import api from "../api/ferramentas"

const notafiscal = {

    buscarConfiguracoes(idAcademia, dataRef) {
        return new Promise((resolve, reject) => {
            api
                .get(`/NotaFiscal/buscar-config-notas/${idAcademia}/${dataRef}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },

    salvalConfiguracoes(configNotas) {
        return new Promise((resolve, reject) => {
            api
                .post("/NotaFiscal/gerenciar-config-notas", configNotas)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
  
    listar(filtros) {
        return new Promise((resolve, reject) => {
            api
                .get(`/NotaFiscal/listar/${filtros.usuario ?? " "}/${filtros.emissao_de}/${filtros.emissao_ate}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
}

export default notafiscal