/**
 * @typedef {Object} Agenda
 * @property {number} dia_semana
 * @property {number} horario
 * @property {number} treinador_id
 */
export default class Agenda {
    constructor(
      dia_semana,
      horario,
      treinador_id,
    ) {
      this.dia_semana = dia_semana
      this.horario = horario
      this.treinador_id= treinador_id
    }
  }
