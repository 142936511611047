<template>    
    <v-container fluid>
    <v-toolbar color="orange" dense>
        <v-toolbar-title>Cadastro Usuário | 2 - Relacionamento </v-toolbar-title>
    </v-toolbar>
    <v-spacer class="mt-4"></v-spacer>
    <v-row align="center" justify="center">
        <v-col col="12" md="12" xl="12" xs="12" sm="12">
            <v-card>
                <v-card-text>
                    <Span>Selecione o tipo de relacionamento</Span>
                    <v-radio-group
                        row
                        v-model="tipo"
                        >
                        <v-radio
                            label="Aluno"
                            value="Aluno"
                        ></v-radio>
                        <v-radio
                            label="Treinador"
                            value="Treinador"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>
                <app-cadastro-aluno v-if="tipo=='Aluno'" :idUsuario="this.id"></app-cadastro-aluno>
                <app-cadastro-treinador v-if="tipo=='Treinador'" :idUsuario="this.id"></app-cadastro-treinador> 
                <v-card-actions>
                <v-col col="12" md="6" xl="6">
                    <v-btn color="#FF562B" width="100%" dark @click="rota('cadastro',)">
                        Voltar
                        <v-icon class="ml-2">mdi-arrow-left-bold-circle-outline</v-icon>
                    </v-btn>
                </v-col>
                <v-col col="12" md="6" xl="6">
                    <v-btn color="#FF562B" width="100%" dark @click="rota('agenda')">
                        Avançar
                        <v-icon class="ml-2">mdi-arrow-right-bold-circle-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-card-actions>
            </v-card>
        </v-col>
    </v-row>        
    </v-container>
</template>

<script>
import AppCadastroAluno from "../view/AppCadastroAluno.vue"
import AppCadastroTreinador from "../view/AppCadastroTreinador.vue"

export default {
    components: {
        AppCadastroAluno,
        AppCadastroTreinador,
    },

    mounted() {
        if (this.$route.params.id != null) {
        this.id = this.$route.params.id;
        }
    },

    data() {
        return {
            id: 0,
            tipo: "",
        }
    },

    methods: {    
    rota(rota) {  
        ///console.log(parametros)    
        this.$router.replace({name:rota, params: {id: this.id }})
      },
    
    //...mapActions({
    //   mostrarCarregando: "carregando/mostrarCarregando",
    //   mostrarMensagem: "mensagem/mostrarMensagem",
    //}),
  },
}
</script>
