<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="6">
      <!-- <v-col cols="12" sm="12" md="6" lg="3"> -->
          <v-alert           
            outlined
            type="success"
            prominent
            border="left"
            >
              <div class="text-h6">
                Pré-Cadastro
              </div>
              <v-row align="center">
                <v-col class="grow" v-if="usuariosnovos == 0">
                  Não há novos usuários.
                </v-col>
                <v-col class="grow" v-else-if="usuariosnovos > 0">
                  {{ usuariosnovos }} novo(s) usuário(s).
                </v-col>                
                <v-col class="shrink">
                  <v-btn icon @click="cadastro('usuarios')" :disabled="usuariosnovos == 0"><v-icon color="success" v-if="usuariosnovos>0">mdi-arrow-right-circle-outline </v-icon></v-btn>
                </v-col>
              </v-row>
          </v-alert>                      
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
      <!-- <v-col cols="12" sm="12" md="6" lg="3"> -->
          <v-alert 
          outlined
          type="info"
          prominent
          border="left"
          >
            <div class="text-h6">
              Aluno
            </div>
            <v-row align="center">              
              <v-col class="grow" v-if="alunosnovos == 0">
                Não há novos alunos.
              </v-col>
              <v-col class="grow" v-else-if="alunosnovos > 0">
                {{ alunosnovos }} novo(s) aluno(s).
              </v-col>              
              <v-col class="shrink">
                <v-btn icon @click="cadastro('alunos')" :disabled="alunosnovos == 0"><v-icon color="info">mdi-arrow-right-circle-outline </v-icon></v-btn>
              </v-col>
            </v-row>
          </v-alert>                      
      </v-col>
      <!-- <v-col cols="12" sm="12" md="6" lg="3">
          <v-alert 
            outlined
            type="warning"
            prominent
            border="left"
            >
              <div class="text-h6">
                Contrato | Aluno
              </div>
              <v-row align="center">
                <v-col class="grow" v-if="contratosnovos == 0">
                  Não há contratos pendentes.
                </v-col>
                <v-col class="grow" v-else-if="contratosnovos > 0">
                  {{ contratosnovos }} contrato(s) não pendente(s).
                </v-col>
                <v-col class="shrink">
                  <v-btn icon @click="cadastro()" :disabled="contratosnovos == 0"><v-icon color="warning">mdi-arrow-right-circle-outline </v-icon></v-btn>
                </v-col>
              </v-row>
          </v-alert>                      
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="3">
          <v-alert 
            outlined
            type="error"
            prominent
            border="left"
            >
              <div class="text-h6">
                Contrato | Financeiro
              </div>
              <v-row align="center">
                <v-col class="grow" v-if="contrfinancnovos == 0">
                  Não há contratos pendentes.
                </v-col>
                <v-col class="grow" v-if="contrfinancnovos > 0">
                  {{ novos }} contrato(s) pendente(s).
                </v-col>
                <v-col class="shrink">
                  <v-btn icon @click="cadastro()" :disabled="contratosnovos == 0"><v-icon color="error">mdi-arrow-right-circle-outline </v-icon></v-btn>
                </v-col>
              </v-row>
          </v-alert>                      
      </v-col> -->
    </v-row>
    <v-row>      
      <!-- <v-col cols="12" sm="4" md="4" lg="4">
        <app-card-numerador titulo="Usuários" icone="account-multiple" cor="rgb(255 86 43 / 90%)"
          fundo="rgb(255 86 43 / 50%)" :total="10" :possui-acao="false"></app-card-numerador>
      </v-col> -->

      <v-col cols="12" sm="4" md="4" lg="4">
        <app-card-numerador titulo="Treinadores" icone="weight-lifter" cor="rgb(125 241 61 / 90%)"
          fundo="rgb(125 241 61 / 50%)" :total="
          this.treinadores" :possui-acao="false" caminho="treinadores">
        </app-card-numerador>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="4">
        <app-card-numerador titulo="Alunos" icone="account-group" cor="rgb(0 84 255 / 90%)" fundo="rgb(0 84 255 / 50%)"
          :total="this.alunos" :possui-acao="false" caminho="alunos"></app-card-numerador>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4">
        <app-card-numerador titulo="Experiência VamoMexe" icone="account-group" cor="rgb(0 84 255 / 90%)" fundo="rgb(0 84 255 / 50%)"
          :total="this.vamomexe" :possui-acao="false" caminho="alunos-desafio"></app-card-numerador>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>            
              <v-icon class="red--text text-h4 align-center">mdi-numeric-1</v-icon>
              <span class="align-center">Dados da Academia</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <h5>Empresa</h5>
                <h3>Let's Move Academia</h3>
              </v-col>

              <v-col cols="6">
                <h5>Endereço</h5>
                <h3>Unidade I | R. Silva Jardim  1031</h3>
                <h3>Unidade II | Avenida Medianeira  533</h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- <v-card>
          <v-card-title>
            <v-card-title-text>
              <span class="headline">Usuários</span>
            </v-card-title-text>
            <v-card-text>Total</v-card-text>
          </v-card-title>
        </v-card>
        <v-card>
          <v-card-title>
            <v-card-title-text>
              <span class="headline">Alunos</span>
            </v-card-title-text>
            <v-card-text>Total</v-card-text>
          </v-card-title>
        </v-card>
        <v-card>
          <v-card-title>
            <v-card-title-text>
              <span class="headline">Treinadores</span>
            </v-card-title-text>
            <v-card-text>Total</v-card-text>
          </v-card-title>
        </v-card> -->
      </v-col>
      <v-col cols="6">
        <v-card class="mt-2 pa-2">
          <v-card-title>
            Aulas
          </v-card-title>
          <v-divider></v-divider>
          <grafico-barras></grafico-barras>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mt-2 pa-2">
          <v-card-title>
            Aulas
          </v-card-title>
          <v-divider></v-divider>
          <grafico-barras></grafico-barras>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppCardNumerador from "./AppCardNumerador.vue"
import GraficoBarras from "./AppGraficoBarra.vue"
import _usuario from '../services/usuario/usuario-servico.js'
import _alunos from '../services/aluno/aluno-servico'

export default {
  components: {
    GraficoBarras,
    AppCardNumerador,
  },
  data() {
    return {
      usuarios: [],
      alunos: 0,
      treinadores: 0,
      vamomexe: 0,
      usuariosnovos: 0,
      alunosnovos: 0,
      contratosnovos: 0,
      contrfinancnovos: 0,
      admin: true,
    };
  },
  
  mounted() {    
    if (localStorage.dados) {
      this.dados = JSON.parse(localStorage.dados);
    }
    if (this.dados["id"] == 636 || this.dados["id"] == 827) {
        this.admin = false
    }
    this.listarUsuarios()        
  },  

  methods: {    
    listarUsuarios() {        
        _usuario.listarUsuariosAtivos().then(response => {
            this.usuarios = response
            this.totalUsuarios()            
        }).catch(error => {
            this.$toast.open({
                message: 'Erro ao listar usuários',
                type: 'error'                
            })
            console.log(error)
        })
        
        _usuario.listarUsuariosNovos().then(response => {
          this.usuariosnovos = response.length          
        }).catch(error => {
            this.$toast.open({
                message: 'Erro ao listar usuários',
                type: 'error'                
            })
            console.log(error)
        })
        
        this.listarAlunos()                
    },
        
    totalUsuarios() {
      var treinadores = 0;
      var alunos = 0;
      var vamomexe = 0;

      this.usuarios.filter((item) => {

        if (item.relacionamento == "Treinador")
          treinadores++;
        else if (item.relacionamento == "Aluno" && (item.experiencia == false || item.experiencia == null))
        {
          if (this.admin == true)
            alunos++;
          else if (this.admin == false && item.academia_id == 2)
            alunos++;
        }
        else if (item.relacionamento == "Aluno" && item.experiencia == true)
        {
          if (this.admin == true)
            vamomexe++;
        }
      })

      this.treinadores = treinadores;
      this.alunos = alunos;
      this.vamomexe = vamomexe;
    },

    listarAlunos() {
      this.filtros = {
          nome: "",
          ativos: true,
          treinador_id: 0,
          academia_id: 0,
          precadastro: true,
          experiencia: false,
          //data_acesso: null,
          //acesso: false,
          //data_inicial: _utils.formatarData(this.data_inicial),        
          //data_final: this.data_final == "" ? null : _utils.formatarData(this.data_final),
        };
      
        console.log(this.filtros)

      _alunos.listarAlunosAtivos(this.filtros).then((response) => {
          this.alunosnovos = response.length
          console.log(response)
        });
    },

    filtrarUsuarios() {
      let tempUsuarios = this.usuarios

      tempUsuarios = tempUsuarios.filter((item) => {
        return(item.relacionamento == "Treinador")
      })

      console.log(tempUsuarios)
      return tempUsuarios;
    },

    cadastro(rota) {
      this.$router.push({ name: rota, params: {novos: true} });
    } 
        
  },  
  //computed: {
    
  //},
};
</script>