const _statusNotaFiscal = [
    {
        valor: 1,
        descricao: "Agendada",
        cor:""
    },
    {
        valor: 2,
        descricao: "Enviada p/ Prefeitura",
        cor:"green lighten-4"
    },
    {
        valor: 3,
        descricao: "Emitida",
        cor:"blue lighten-4"
    },
    {
        valor: 4,
        descricao: "Processando Cancelamento",
        cor:"yellow lighten-4"
    },
    {
        valor: 5,
        descricao: "Cancelada",
        cor:"red lighten-4"
    },
    {
        valor: 6,
        descricao: "Cancelamento Negado",
        cor:"red lighten-4"
    },
    {
        valor: 7,
        descricao: "Erro",
        cor:"red lighten-4"
    },
]

export default _statusNotaFiscal