<template>
    <v-app>
      <v-app-bar color="#FF562B" dark fixed app>
        <v-app-bar-nav-icon icon @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-title>icaro | Let's Move Academia</v-app-bar-title>
      </v-app-bar>
        <v-container>
            <v-layout>
               <v-flex xs12 sm12 md12>
                  <v-row>
                    <v-col col="8" xl="3" md="3">
                            <v-card
                              class="mx-auto"
                              max-width="400"
                              color="#F9F9FB"
                              height="332"
                            >
                              <v-list-item two-line>
                                <v-list-item-content>
                                  <v-list-item-title class="text-h5">
                                    Alunos
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-card-text>
                                <v-row align="center">
                                  <v-col
                                    class="text-h2"
                                    cols="6"
                                  >
                                    315
                                  </v-col>
                                  <v-col cols="6">
                                    <v-img
                                      src="../assets/1744.jpg"
                                      alt="Sunny image"
                                      width="80px"
                                    ></v-img>
                                  </v-col>
                                </v-row>
                              </v-card-text>    
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-btn text>
                                  Consultar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col col="2" xl="3" md="3">
                          <v-card
                              class="mx-auto"
                              max-width="400"
                              color="#F9F9FB"
                              height="332"
                            >
                              <v-list-item two-line>
                                <v-list-item-content>
                                  <v-list-item-title class="text-h5">
                                    Treinadores
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-card-text>
                                <v-row align="center">
                                  <v-col
                                    class="text-h2"
                                    cols="6"
                                  >
                                    8
                                  </v-col>
                                  <v-col cols="6">
                                    <v-img
                                      src="../assets/1744.jpg"
                                      alt="Sunny image"
                                      width="80px"
                                    ></v-img>
                                  </v-col>
                                </v-row>
                              </v-card-text>    
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-btn text>
                                  Consultar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col col="2" xl="6" md="6">
                            <v-card>
                              <v-img
                                src="../assets/1745.jpg"
                                class="white--text align-top"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                height="150px"              
                                >
                                <v-card-title>Matrículas / Cancelamentos</v-card-title>
                            </v-img>
                            <v-card-text>
                              <GraficoLinha/>
                            </v-card-text>
                              
                            </v-card>
                        </v-col>
                      </v-row>
                    <v-row>
                        <v-col xl="4" md="4">
                          <v-card height="380">
                                <v-card-title>Recebimentos</v-card-title>
                                <v-card-text>
                                    <GraficoPizza/>
                                </v-card-text>
                            </v-card>                            
                        </v-col>
                        <v-col xl="4" md="4">                            
                          <v-card height="380">
                                <v-card-title>Total de Aulas</v-card-title>
                                <v-card-text>
                                    <GraficoBarras/>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col xl="4" md="4">
                            <v-card height="380">
                                <v-card-title>Alunos por Treinador</v-card-title>
                                <v-card-text>
                                    <GraficoRosca/>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-flex>
            </v-layout>
        </v-container>
    </v-app>
</template>>


<script>
import GraficoBarras from './AppGraficoBarra.vue'
import GraficoPizza from './AppGraficoPizza.vue'
import GraficoLinha from './AppGraficoLinha.vue'
import GraficoRosca from './AppGraficoRosca.vue'
    
export default {
    name: 'App',
    components: { GraficoBarras, GraficoPizza, GraficoLinha, GraficoRosca }
    }
</script>