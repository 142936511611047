const _statusCobranca = [
    {
        valor: 1,
        descricao: "Pendente",
        cor:""
    },
    {
        valor: 2,
        descricao: "Recebida",
        cor:"green lighten-4"
    },
    {
        valor: 3,
        descricao: "Confirmada",
        cor:"blue lighten-4"
    },
    {
        valor: 4,
        descricao: "Atrasada",
        cor:"red lighten-4"
    },
    {
        valor: 5,
        descricao: "Estornada",
        cor:"yellow lighten-4"
    },
    {
        valor: 6,
        descricao: "Em Dinheiro",
        cor:"green lighten-4"
    },
    {
        valor: 99,
        descricao: "Desconhecida",
        cor:""
    },
]

export default _statusCobranca