import api from "../api/icaro"

const aula = {
    /**
   *
   * @returns {Promise} response.data ou erro
   */
    listarAulaDia(aluno_id, data_aula) {
        return new Promise((resolve, reject) => {
            api
                .get(`/aula/${aluno_id}/${data_aula}`)
                .then((response) => {
                resolve(response.data)
                })
                .catch((erro) => {
                reject(erro)
                })
            })
    },

    listarAulaPeriodo(aluno_id, data_inicial, data_final) {
        return new Promise((resolve, reject) => {
            api
                .get(`/aula/${aluno_id}/${data_inicial}/${data_final}`)
                .then((response) => {
                resolve(response.data)
                })
                .catch((erro) => {
                reject(erro)
                })
            })
    },

    gravarAula(aula) {
        return new Promise((resolve, reject) => {
            api
                .post(`/aulas/`, aula)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                reject(erro)
                })
            })
    },

    deletarAula(id) {
        return new Promise((resolve, reject) => {
            api
                .delete(`/aulas/${id}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((erro) => {
                    reject(erro)
                })
        })
    },
}

export default aula