<template>   
  <div class="calendario-semanal" style="overflow-x: auto;">
    <div class="filtro">
  <label for="filtro-nome">Filtrar por treinador:</label>
  <input id="filtro-nome" v-model="filtroNome" type="text" placeholder="Digite um nome" class="input-filtro">

  <label for="select-nome">Selecione o treinador:</label>
  <select id="select-nome" v-model="selectedNome" @change="filterBySelectedNome" class="select-filtro">
    <option value="" disabled>Selecione um nome</option>
    <option value="">Todos</option>
    <option v-for="name in uniqueNames" :key="name" :value="name">
      {{ name }}
    </option>
  </select>

  <label for="filtro-alunos">Por número:</label>
  <input id="filtro-alunos" v-model.number="filtroAlunos" type="number" placeholder="Número de alunos" class="input-filtro">

  <button @click="limparFiltro" class="botao-limpar">Limpar</button>
</div>
      <table>
          <thead>
              <tr>
                  <th style="background-color: white;"></th>
                  <th v-for="dia in dias" :key="dia">
                      <span :class="{ 'hoje': isToday(dia) }">{{ dia }}</span>
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="horario in horariosVisiveis" :key="horario">
                  <td style="width: 50px;">{{ horario.substring(0,2) + 'h' }}</td>
                  <td style="min-width: 3px;" v-for="dia in dias" :key="dia">
                      <div v-for="evento in getEventosParaDia(dia, horario)" :key="evento.id" class="d-inline-block ma-2">
                          <v-badge :content="evento.alunos > 0 ? evento.alunos : String(evento.alunos)" :title="evento.nome" overlap bordered :color="badgeColor(evento.alunos)" class="custom-badge">
                              <v-avatar size="30" color="orange" v-if="evento.experiencia==false"
                                  @click="abreModal(evento.nome, dia, horario, evento.dia_semana, evento.treinador_id)">
                                  <v-img :src="evento.avatar" v-if="evento.avatar"></v-img>
                                  <span v-else>{{ inicialNome(evento.nome) }}</span>
                              </v-avatar>
                          </v-badge>
                          <br/>
                          <span style="font-size: 12px;">{{ formataNome(evento.nome) }}</span>                            
                      </div>                
                  </td>
              </tr>
              <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                      <v-toolbar color="primary" dark>{{ titulo }}</v-toolbar>
                      <v-card-text>
                          <div v-for="aluno in alunos" :key="aluno.id" class="ma-2">
                              {{ aluno.nome }}
                          </div>                        
                      </v-card-text>
                      <v-card-actions class="justify-end">
                          <v-btn text @click="fechaModal()">Fechar</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
          </tbody>
      </table>        
  </div>
</template>

<script>
import _utils from '../utils/utils.js'
import _treinador from '../services/treinador/treinador-servico.js'
import _alunos from '../services/aluno/aluno-servico.js'

export default {        
  data() {
      return {
          filtroNome: "",
          filtroAlunos: null, // Filtro por número de alunos
          selectedNome: "", // Novo modelo para o nome selecionado
          dias: ["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
          horarios: [
              "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
              "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00",
          ],
          eventos: [],
          dialog: false,
          titulo: "",
          alunos: [],
      };
  },
  async mounted() {
      this.eventos = await _treinador.listarAgendaGeral()
  },
  computed: {
      uniqueNames() {
          const namesSet = new Set(this.eventos.map(evento => evento.nome));
          return Array.from(namesSet).sort();
      },
      eventosFiltrados() {
            return (dia, horario) => {
                return this.getEventosParaDia(dia, horario).filter(evento => {
                    const matchNome = this.filtroNome ? evento.nome.toLowerCase().includes(this.filtroNome.toLowerCase()) : true;
                    const matchTreinador = this.selectedNome ? evento.nome === this.selectedNome : true;
                    const matchAlunos = this.filtroAlunos !== null ? evento.alunos >= this.filtroAlunos : true;

                    return matchNome && matchTreinador && matchAlunos;
                });
            };
        },
        temEventosVisiveis() {
            return horario => {
                return this.dias.some(dia => this.eventosFiltrados(dia, horario).length > 0);
            };
        },
        horariosVisiveis() {
          return this.horarios.filter(horario => this.temEventosVisiveis(horario));
        }
    },
  methods: {
        ordenarEventos() {
          const nomesUnicos = Array.from(new Set(this.eventos.map(evento => evento.nome))); // Cria um array de nomes únicos
          return nomesUnicos.sort();
      },
      getEventosParaDia(dia, horario) {
        console.log(dia, horario)
          const events =  this.eventos.filter(evento => 
              evento.dia === dia && 
              evento.horario === horario &&
              (this.filtroNome === "" || evento.nome.toLowerCase().includes(this.filtroNome.toLowerCase())) &&
              (this.selectedNome === "" || evento.nome === this.selectedNome) && // Filtro do select
              (this.filtroAlunos === null || evento.alunos === this.filtroAlunos) // Filtro por número de alunos
           // Filtra pelo nome selecionado
          );
          console.log(events)
          return events
      },
      isToday(dia) {
          const hoje = new Date().toLocaleString('pt-BR', { weekday: 'long' }).split('-')[0].trim();        
          return dia.toLowerCase() === hoje.toLowerCase()
      },
      formataNome(nome){
          return _utils.formataNomeCompleto(nome)            
      },
      inicialNome(nome){                        
          return nome[0]
      },
      async abreModal(nome, dia, horario, dia_semana, treinador){
          this.titulo = nome + " | " + dia + " | " + horario
          this.dialog = true
          this.alunos = await this.buscaAlunos(treinador, dia_semana, horario)            
      },
      buscaAlunos(id, dia, horario) {            
          return _alunos.buscarAlunosTreinadorDia(id, dia, horario)
      },
      fechaModal() {
          this.dialog = false
          this.titulo = ""
          this.alunos = []
      },
      badgeColor(alunos) {
          if (alunos <= 5) {
              return 'green'; // Cor verde para até 5 alunos
          } else if (alunos === 6) {
              return 'yellow'; // Cor amarela para exatamente 6 alunos
          } else {
              return 'red'; // Cor vermelha para mais de 6 alunos
          }
      },
      filterBySelectedNome() {
          // A lógica de filtragem é realizada na computed 'getEventosParaDia'
      },

      limparFiltro() {
        this.filtroNome = "";
        this.selectedNome = ""// Reseta o filtro para uma string vazia
        this.filtroAlunos = null
    },
  },
};
</script>

<style scoped>
.calendario-semanal {
  font-family: Arial, sans-serif;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}

table th.hoje {
  background-color: #ffcc00;
}

table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.evento {
  background-color: #007bff;
  color: #fff;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}

.hoje {
  color: #ffcc00;
}

.filtro {
  display: flex;
  flex-direction: row; /* Mantém os elementos em linha */
  gap: 15px; /* Espaçamento entre os elementos */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  padding: 15px; /* Espaçamento interno */
  background-color: #f8f9fa; /* Cor de fundo clara */
  border: 1px solid #ced4da; /* Borda leve */
  border-radius: 8px; /* Bordas arredondadas */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
  flex-wrap: wrap; /* Permite que os itens quebrem em novas linhas em telas menores */
}

.input-filtro, .select-filtro {
  padding: 10px; /* Preenchimento interno */
  border: 1px solid #ced4da; /* Borda leve */
  border-radius: 4px; /* Bordas arredondadas */
  transition: border-color 0.3s; /* Transição para efeito de foco */
  font-size: 14px; /* Tamanho da fonte */
  min-width: 120px; /* Largura mínima para inputs e selects */
  flex-grow: 1; /* Permite que os inputs ocupem espaço disponível */
}

.botao-limpar {
  padding: 10px 15px; /* Preenchimento interno do botão */
  background-color: #007BFF; /* Cor de fundo do botão */
  color: white; /* Cor do texto do botão */
  border: none; /* Remove a borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  cursor: pointer; /* Cursor como mão ao passar o mouse */
  transition: background-color 0.3s; /* Transição para efeito de hover */
  font-size: 14px; /* Tamanho da fonte do botão */
}

.botao-limpar:hover {
  background-color: #0056b3; /* Cor do botão ao passar o mouse */
}

/* Estilo da tabela */
table {
  width: 100%; /* A tabela ocupa 100% da largura do contêiner */
  border-collapse: collapse; /* Remove espaços entre células */
  margin-top: 15px; /* Margem superior para espaçamento */
}

th, td {
  border: 1px solid #ced4da; /* Borda leve para células */
  padding: 8px; /* Espaçamento interno */
  text-align: left; /* Alinhamento do texto à esquerda */
  font-size: 14px; /* Tamanho da fonte */
}

@media (max-width: 768px) {
  .filtro {
    flex-direction: column; /* Muda para coluna em telas menores */
    align-items: flex-start; /* Alinha os itens à esquerda */
  }

  .input-filtro, .select-filtro {
    width: 100%; /* Faz com que os inputs e selects ocupem toda a largura disponível */
  }
}

/* Media query específica para orientação horizontal em dispositivos móveis */
@media (max-width: 768px) and (orientation: landscape) {
  /* Ajuste específico para o campo de número de alunos */
  #filtro-alunos {
    max-width: 150px; /* Largura máxima para o campo de número */
    flex-grow: 0; /* Impede que o campo ocupe espaço extra */
    flex-basis: auto; /* Ajusta a base flexível para o tamanho automático */
  }
}


::v-deep(.v-badge__badge) {
  color: white; /* Cor da fonte */
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7); /* Sombra no texto */
  font-weight: bold; /* Fonte em negrito */
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5); Sombra ao redor do badge */
}

@media (max-width: 768px) {
table {
      font-size: 12px;
  }
}
</style>