<template>
  <div class="snackbar" :class="color">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    color: String, // Cor do snackbar
    text: String,  // Texto do snackbar
  },
};
</script>

<style scoped>
.snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  color: white;
  border-radius: 4px;
  z-index: 1000;
}

.success {
  background-color: green;
}

.error {
  background-color: red;
}

.info {
  background-color: blue;
}

/* Adicione estilos para outras cores, se necessário */
</style>
