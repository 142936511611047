<template>   
    <v-container fluid>        
        <AppBar titulo="Avaliação" destino="alunos"/>
        <AppAlerta :snack="snack" />
        <v-row align="center" justify="center">
            <v-col col="12" md="12" xl="12" xs="12" sm="12">
                <v-card>
                    <ValidationObserver ref="form" #default="{ handleSubmit }">
                        <v-form  @keyup.enter="handleSubmit(salvar)" 
                                    @submit.prevent="handleSubmit(salvar)">                        
                            <v-card-text>
                                <v-row>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="nome"
                                            label="Aluno"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="treinador"
                                            label="Treinador"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="4" md="4" xl="4">
                                        <ValidationProvider 
                                            #default="{ errors }"
                                            :rules="{ required: true }"
                                            name="Data">
                                        <v-text-field
                                            v-model="data"
                                            label="Data"
                                            color="#FF562B"
                                            outlined
                                            v-mask="'##/##/####'"
                                            :error-messages="errors[0]">
                                        </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            ref="idade"
                                            v-model="idade"
                                            label="Idade"
                                            color="#FF562B"
                                            outlined>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="roda_saude"
                                            label="Roda da Saúde"
                                            color="#FF562B"
                                            outlined
                                            >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="12" md="12" xl="12">
                                        <center>
                                            <h3>Dobras Cutâneas</h3>
                                        </center>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="massa_corporal"
                                            label="Massa Corporal (MC) Kg"
                                            color="#FF562B"
                                            outlined
                                            validate-on-blur
                                            v-on:blur="calculagordura()">
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="dobra_peito"
                                            :label="triceps_peito"
                                            color="#FF562B"                                            
                                            outlined
                                            validate-on-blur
                                            v-on:blur="somadobras()">
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="dobra_abdominal"
                                            label="Abdominal (mm)"
                                            color="#FF562B"
                                            outlined
                                            validate-on-blur
                                            v-on:blur="somadobras()">
                                        </v-text-field>
                                    </v-col>
                                    
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="dobra_coxa"
                                            label="Coxa (mm)"
                                            color="#FF562B"
                                            outlined
                                            validate-on-blur
                                            v-on:blur="somadobras()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                
                                <v-row>
                                    <v-col col="12" md="12" xl="12">
                                        <center>
                                            <h3>Circunferências</h3>
                                        </center>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="circunferencia_braco"
                                            label="Braço (cm)"
                                            color="#FF562B"
                                            outlined>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="circunferencia_peito"
                                            label="Peito (cm)"
                                            color="#FF562B"
                                            outlined>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="circunferencia_cintura"
                                            label="Cintura (cm)"
                                            color="#FF562B"
                                            outlined
                                            validate-on-blur
                                            v-on:blur="calcularcq()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="circunferencia_abdominal"
                                            label="Abdominal (cm)"
                                            color="#FF562B"
                                            outlined>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="circunferencia_quadril"
                                            label="Quadril (cm)"
                                            color="#FF562B"
                                            outlined
                                            validate-on-blur
                                            v-on:blur="calcularcq()">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="circunferencia_coxa"
                                            label="Coxa (cm)"
                                            color="#FF562B"
                                            outlined>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="circunferencia_panturrilha"
                                            label="Panturrilha (cm)"
                                            color="#FF562B"
                                            outlined>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="12" md="12" xl="12">
                                        <center>
                                            <h3>Resultados % Gordura Corporal</h3>
                                        </center>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="soma_dobras"
                                            label="Soma Dobras (X) mm"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="dc"
                                            label="DC (U/m)"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="gordura_corporal"
                                            label="Gordura Corporal (%GC)"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="massa_gorda"
                                            label="Massa Gorda (MG) kg"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-text-field
                                            v-model="massa_livre_gordura"
                                            label="Massa Livre de Gordura (MLG) kg"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="12" md="12" xl="12">
                                        <center>
                                            <h3>Relação Cintura/Quadril (RCQ)</h3>
                                        </center>
                                    </v-col>
                                </v-row>
                                <v-row>
                                <v-col col="12" md="12" xl="12">
                                        <v-text-field
                                            v-model="rcq"
                                            label="Resultado"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider />
                                <v-card-actions>
                                    <v-row>
                                        <v-col col="12" md="6" xl="6">
                                            <v-btn :disabled="isDisabled" color="#FF562B" width="100%" type="submit" dark>Salvar</v-btn>
                                        </v-col>
                                        <v-col col="12" md="6" xl="6">
                                            <v-btn :disabled="isDisabled" @click="cancelar" color="grey" width="100%" dark>Cancelar</v-btn>
                                        </v-col>
                                    </v-row>                                    
                                </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
        </v-row>        
    </v-container>
</template>

<script>
//Componentes
import { ValidationObserver, ValidationProvider } from "vee-validate"
import AppAlerta from "../../components/AppAlerta.vue";
import AppBar from "../../components/AppBar.vue"

//Classes
import _utils from '../../utils/utils.js'
import _alunoAvaliacao from '../../services/aluno/aluno-avaliacao-servico.js'

export default {    
    components: {   
    ValidationObserver,
    ValidationProvider, 
    AppBar,
    AppAlerta,
},
    data () {
        return { 
            dialogs: {
                dialog: false
            },
            snack: { 
                snackbar: false,               
                texto: '',
                cor: ''
            },
            id: 0,
            sexo: '',
            aluno: [],
            nome: '',
            treinador: '',
            data: '',
            idade: '',
            roda_saude: '',
            dobra_peito: '',
            triceps_peito: '',
            dobra_abdominal: '',
            dobra_coxa: '',
            massa_corporal: '',
            circunferencia_braco: '',
            circunferencia_peito: '',
            circunferencia_cintura: '',
            circunferencia_abdominal: '',
            circunferencia_quadril: '',
            circunferencia_coxa: '',
            circunferencia_panturrilha: '',
            soma_dobras: '',
            dc: '',
            gordura_corporal: '',
            massa_gorda: '',
            massa_livre_gordura: '',
            rcq: '',
            avaliacao: [],
            isDisabled: false,
        }
    },
    mounted() {        
        if (this.$route.params.id != null)
        {
            this.id = this.$route.params.id
            this.buscar(this.id)
        }

        if (this.$route.params.aluno != null)
        {                                       
            this.aluno = this.$route.params.aluno            
            this.nome = this.aluno.nome
            this.treinador = this.aluno.treinador
            this.sexo = this.aluno.sexo
            this.data = this.dataAtual()           
            this.idade = this.aluno.idade_atual 
            this.roda_saude = this.aluno.roda_saude > 0 ? this.aluno.roda_saude : 0
            this.triceps_peito = this.sexo == 'F' ? "Trícpes (mm)" : "Peito (mm)" 
            this.$refs.idade.focus();
            if (this.id == 0)
            {
                this.SalvarDesativado = false
            }
        }
        else {
            this.$router.push({ name: 'alunos' })
        }
    },
    methods:
    {
        somadobras()
        {
            this.soma_dobras = (parseFloat(this.dobra_peito.toString().replace(',', '.')) + parseFloat(this.dobra_abdominal.toString().replace(',', '.')) + parseFloat(this.dobra_coxa.toString().replace(',', '.'))).toFixed(2)
            if (this.soma_dobras.toString() == 'NaN')
                this.soma_dobras = 'Calculando...'
            else
                this.calculadc()            
        },
        calculadc()
        {            
            //this.dc = 1.0994921-(0.0009929*(parseFloat(this.soma_dobras)))+(0.0000023*((parseFloat(this.soma_dobras)*parseFloat(this.soma_dobras)))-(0.0001392*(parseInt(this.idade))))
            //this.dc = 1.10938-(0.0008267*(parseFloat(this.soma_dobras)))+(0.0000016*((parseFloat(this.soma_dobras)*parseFloat(this.soma_dobras)))-(0.0002574*(parseInt(this.idade))))
            if (this.aluno.sexo == 'F')                
                this.dc = 1.0994921-(0.0009929*(parseFloat(this.soma_dobras.toString().replace(',', '.'))))+(0.0000023*((parseFloat(this.soma_dobras.toString().replace(',', '.'))*parseFloat(this.soma_dobras.toString().replace(',', '.'))))-(0.0001392*(parseInt(this.idade))))
            else                
                this.dc = 1.10938-(0.0008267*(parseFloat(this.soma_dobras.toString().replace(',', '.'))))+(0.0000016*((parseFloat(this.soma_dobras.toString().replace(',', '.'))*parseFloat(this.soma_dobras.toString().replace(',', '.'))))-(0.0002574*(parseInt(this.idade))))
        },
        calculagordura()
        { 
            this.gordura_corporal = ((495/parseFloat(this.dc))-450).toFixed(2)
            this.calculamassagorda()
        },
        calculamassagorda()
        {
            
            this.massa_gorda = (parseFloat(this.massa_corporal.toString().replace(',', '.'))*(parseFloat(this.gordura_corporal)/100)).toFixed(2)
            this.calculamassalivregordura()
        },
        calculamassalivregordura()
        {
            this.massa_livre_gordura = parseFloat(this.massa_corporal.toString().replace(',', '.'))-parseFloat(this.massa_gorda).toFixed(2)
        },
        calcularcq()
        {
            this.rcq = (parseFloat(this.circunferencia_cintura.toString().replace(',', '.'))/parseFloat(this.circunferencia_quadril.toString().replace(',', '.'))).toFixed(2)
        },
        buscar(id)
        {
            _alunoAvaliacao.buscarAvaliacaoAluno(id).then((response)=> {                
                this.data = _utils.formatarDataTela(response.data)
                this.idade = response.idade
                this.roda_saude = response.roda_saude,
                this.dobra_peito = response.dobra_peito,
                this.dobra_abdominal = response.dobra_abdominal,
                this.dobra_coxa = response.dobra_coxa,
                this.massa_corporal = response.massa_corporal,
                this.circunferencia_braco = response.circunferencia_braco,
                this.circunferencia_peito = response.circunferencia_peito,
                this.circunferencia_cintura = response.circunferencia_cintura,
                this.circunferencia_abdominal = response.circunferencia_abdominal,
                this.circunferencia_quadril = response.circunferencia_quadril,
                this.circunferencia_coxa = response.circunferencia_coxa,
                this.circunferencia_panturrilha = response.circunferencia_panturrilha,
                this.soma_dobras = response.soma_dobras,
                this.dc = response.dc,
                this.gordura_corporal = response.gordura_corporal,
                this.massa_gorda = response.massa_gorda,
                this.massa_livre_gordura = response.massa_livre_gordura
                this.rcq = response.rcq
            })
        },
        salvar()
        {       
            console.log(this.aluno)     
            this.avaliacao = {
                aluno_id: this.aluno.id_aluno,
                treinador_id: this.aluno.id_treinador,
                data: _utils.formatarData(this.data),
                idade: this.idade,
                roda_saude: this.roda_saude.toString().replace(',', '.'),
                dobra_peito: this.dobra_peito.toString().replace(',', '.'),
                dobra_abdominal: this.dobra_abdominal.toString().replace(',', '.'),
                dobra_coxa: this.dobra_coxa.toString().replace(',', '.'),
                massa_corporal: this.massa_corporal.toString().replace(',', '.'),
                circunferencia_braco: this.circunferencia_braco.toString().replace(',', '.'),
                circunferencia_peito: this.circunferencia_peito.toString().replace(',', '.'),
                circunferencia_cintura: this.circunferencia_cintura.toString().replace(',', '.'),
                circunferencia_abdominal: this.circunferencia_abdominal.toString().replace(',', '.'),
                circunferencia_quadril: this.circunferencia_quadril.toString().replace(',', '.'),
                circunferencia_coxa: this.circunferencia_coxa.toString().replace(',', '.'),
                circunferencia_panturrilha: this.circunferencia_panturrilha.toString().replace(',', '.'),
                soma_dobras: this.soma_dobras,
                dc: this.dc,
                gordura_corporal: this.gordura_corporal,
                massa_gorda: this.massa_gorda,
                massa_livre_gordura: this.massa_livre_gordura,
                rcq: this.rcq,
            }

            console.log(this.avaliacao)

            if (this.id == 0) {
                this.cadastrar()
            } else {
                this.atualizar()
            }
        },
        cadastrar()
        {
            _alunoAvaliacao.cadastrarAvaliacao(this.avaliacao).then((response) => {
                this.id = response.id                
                this.snack.texto = 'Avaliação cadastrada com sucesso!'
                this.snack.cor = 'success';
                this.snack.snackbar = true;
                this.$router.push({name:'lista-avaliacao', params: {aluno:this.aluno}})
            })            
        },
        atualizar()
        {
            _alunoAvaliacao.atualizarAvaliacao(this.id, this.avaliacao).then(() => {
                this.snack.texto = 'Avaliação atualizada com sucesso!'
                this.snack.snackbar = true;
                //alert("Avaliação atualizada")
                //setTimeout(() => {  this.$router.push({ name: 'usuarios' }) }, 1000);
                this.$router.push({name:'lista-avaliacao', params: {aluno: this.aluno}})
            })        
        },        
        cancelar()
        {         
            this.$router.replace({name:'alunos'})
        },
        dataAtual() {
            const current = new Date();
            const day = String(current.getDate()).padStart(2, '0');
            const month = String(current.getMonth() + 1).padStart(2, '0');
            const year = current.getFullYear();
            const date = `${day}/${month}/${year}`;
            
            return date;
        }        
    }
}
</script>