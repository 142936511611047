<template>
  <v-dialog v-model="dialogo" max-width="auto" persistent>
    <v-card class="">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center">
            Cadastrar
          </v-list-item-title>
        </v-list-item-content>
        <v-spacer />
        <v-btn icon @click="dialogo = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider class="mx-4" />
      <ValidationObserver ref="form" #default="{ handleSubmit }">
        <v-form
          @keyup.enter="handleSubmit(salvar)"
          @submit.prevent="handleSubmit(salvar)"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="Descrição"
                >
                  <v-text-field
                    v-model="DreLancamento.descricao"
                    label="Descrição"
                    :error-messages="errors[0]"
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" xl="4">
                <v-menu
                  v-model="menuDatePickerI"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="Data Inicial"
                    >
                      <v-text-field
                        v-model="DreLancamento.data_inicial"
                        label="Data Inicial"
                        prepend-inner-icon="mdi-calendar"
                        :error-messages="errors[0]"
                        outlined
                        v-bind="attrs"
                        clearable
                        v-on="on"
                      />
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="dataInicial"
                    :active-picker.sync="ativarDatePicker"
                    min="1900-01-01"
                    locale="pt-BR"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" xl="4">
                <v-menu
                  v-model="menuDatePickerF"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="Data final"
                    >
                      <v-text-field
                        v-model="DreLancamento.data_final"
                        label="Data final"
                        prepend-inner-icon="mdi-calendar"
                        :error-messages="errors[0]"
                        outlined
                        v-bind="attrs"
                        clearable
                        v-on="on"
                      />
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="dataFinal"
                    :active-picker.sync="ativarDatePicker"
                    min="1900-01-01"
                    locale="pt-BR"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" xl="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="Valor"
                  v-money="moneyConfig"
                >
                  <v-text-field
                    v-model="valor"
                    label="Valor"
                    :error-messages="errors[0]"
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="#FF562B" type="submit" dark
              >Salvar <v-icon class="ml-2">mdi-content-save</v-icon></v-btn
            >
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import _utils from "../../utils/utils.js";
import DreLancamento from "../../models/dre-lancamento.js";

import _dreLancamento from "../../services/financeiro/dre-lancamento.js";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    value: Boolean,
    tipo: { require: true, type: Number },
    dre_id: { require: true, type: Number },
  },
  data() {
    return {
      util: _utils,
      DreLancamento: new DreLancamento(),

      dataInicial: null,
      menuDatePickerI: false,

      dataFinal: null,
      menuDatePickerF: false,

      ativarDatePicker: null,

      valor: 0,

      moneyConfig: {
        // Configuration options for v-money
        decimal: ",",
        // Decimal separator
        thousands: ".",
        // Thousands separator
        prefix: "R$ ",
        // Currency symbol
        precision: 2,
        // Number of decimal places
        masked: false,
        // Do not show placeholders for unfilled digits
        allowBlank: false,
        // Do not allow an empty value
      },
    };
  },
  watch: {
    dataInicial() {
      this.DreLancamento.data_inicial = this.util.converterFormatoPtBr(
        this.dataInicial
      );
    },
    dataFinal() {
      this.DreLancamento.data_final = this.util.converterFormatoPtBr(
        this.dataFinal
      );
    },
  },
  computed: {
    dialogo: {
      get() {
        this.buscar();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    buscar() {},

    salvar() {
      this.DreLancamento.dre_id = this.dre_id;
      this.DreLancamento.tipo = this.tipo;
      this.DreLancamento.valor = parseFloat(
        this.valor.replace(/[^\d-,]/g, "").replace(",", ".")
      );
      this.DreLancamento.data_inicial = _utils.formatarData(
        this.DreLancamento.data_inicial
      );
      this.DreLancamento.data_final = _utils.formatarData(
        this.DreLancamento.data_final
      );

      console.log(this.DreLancamento);
      _dreLancamento.salvar(this.DreLancamento).then(() => {
        this.fecharDialogo();
      });
    },

    fecharDialogo() {
      this.$root.$refs.AppFinanceiro.buscar();
      this.dialogo = false;
    },
  },
};
</script>