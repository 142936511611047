/**
 * @typedef {Object} LoginRequest
 * @property {string} usuario
 * @property {string} senha
 */
 export default class LoginRequest {    

    constructor (usuario, senha) {
      this.login = usuario
      this.senha = senha
    }
}