<template>
  <div>
  <v-container fluid>
    <AppBar titulo="" destino="dashboard" />
    <v-toolbar color="orange" dense>
      <v-toolbar-title>Alunos</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <br />
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <v-card-title> Filtros </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-text-field label="Nome" v-model="nome" outlined></v-text-field>
              </v-col>                         
              <v-col cols="3">
                <v-select
                        v-model="treinador"
                        :items="treinadores"
                        label="Treinador"                        
                        item-text="nome"
                        item-value="id"
                        outlined
                      />
              </v-col>
              <v-col cols="3">
                <v-select
                        v-model="academia"
                        :items="academias"
                        label="Unidade"
                        item-text="nome"
                        item-value="id"
                        outlined
                      />
              </v-col>
              <v-col cols="3">
                <v-select
                        v-model="produto"
                        :items="produtos"
                        label="Produto"
                        outlined
                      />
              </v-col>
            </v-row>  
              <v-row>      
                <!-- <v-col cols="3"><v-checkbox label="Todos" v-model="todos"></v-checkbox></v-col>                  -->
                <!-- <v-col cols="3"><v-switch v-model="pagos" label="Pagos" color="success"></v-switch></v-col> -->
                <v-col cols="3">
                <v-select
                        v-model="situacao"
                        :items="situacoes"
                        label="Situação"
                        outlined
                      />
              </v-col>
              <v-col cols="3">
              <v-select
                v-model="avaliacaoFiltro"
                :items="avaliacaoOpcoes"
                label="Filtro de Avaliação"
                outlined
              />
            </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue" dark v-on:click="listarAlunos()"
              >Buscar <v-icon class="ml-2">mdi-magnify</v-icon></v-btn
            >
            <v-btn color="#ccc" dark @click="limparCampos()">Limpar<v-icon class="ml-2">mdi-broom</v-icon></v-btn>
            <!-- <v-btn
              class="ml-2"
              color="green"
              dark
              >Imprimir<v-icon class="ml-2">mdi-printer-pos-outline</v-icon></v-btn
            > -->
            <v-btn
              class="ml-auto"
              color="#FF562B"
              dark
              v-on:click="rota('cadastro')"
              >Cadastrar <v-icon class="ml-2">mdi-plus</v-icon></v-btn
            >            
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <h3 class="mb-2">Lista de alunos</h3>        
        <v-card>
          <v-data-table
            :headers="headers"
            :items="alunos"
            :items-per-page="10"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Itens por página',
            }"
          > 
          <template v-slot:[`item.situacao_aluno`]="{ item }">
            <span>
              {{ getSituacao(item.situacao_aluno) }}
            </span>
          </template>
          <template v-slot:[`item.contrato_periodo`]="{ item }">            
              {{  item.contrato_json ? formatarData(item.contrato_json.data_inicial) + " - " + formatarData(item.contrato_json.data_final) : '' }}
            </template>         
            <template v-slot:[`item.contrato_ini`]="{ item }">            
              {{  item.contrato_json ? formatarData(item.contrato_json.data_inicial) : '' }}
            </template>
            <template v-slot:[`item.contrato_fin`]="{ item }">            
              {{  item.contrato_json ? formatarData(item.contrato_json.data_final) : '' }}              
            </template>
            <template v-slot:[`item.valor`]="{ item }">            
              {{  item.contrato_json ? item.contrato_json.valor : '' }}              
            </template>
            <template v-slot:[`item.parcelas`]="{ item }">            
              {{  item.contrato_json ? item.contrato_json.forma_pagamento : '' }}              
            </template>
            <template v-slot:[`item.situacao`]="{ item }">            
              <v-icon v-if="item.pagmento_json && pago" medium class="mr-2" color="green" @click="abrirModal(item)">mdi-thumb-up</v-icon>
              <v-icon v-if="item.pagmento_json && !pago" medium  class="mr-2" color="red" @click="abrirModal(item)">mdi-thumb-down</v-icon>
            </template>
            <template v-slot:[`item.cadastro`]="{ item }">
              <v-icon medium class="mr-2" @click="editarAluno(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <template v-slot:[`item.observacoes`]="{ item }">              
              <v-icon v-if="item.obs_aluno || item.obs_agenda || item.obs_contrato" medium class="mr-2" @click="abreModal(item.nome, item.treinador, item.obs_aluno, item.obs_agenda, item.obs_contrato)">
                mdi-note-text-outline
              </v-icon>
            </template>
            <!-- <template v-slot:[`item.contrato`]="{ item }">
              <v-icon medium class="mr-2" @click="editarContrato(item)">
                mdi-file-sign
              </v-icon>
            </template>
            <template v-slot:[`item.agenda`]="{ item }">
              <v-icon medium class="mr-2" @click="editarAgenda(item)">
                mdi-calendar-clock-outline
              </v-icon>
            </template> 
            <template v-slot:[`item.anamnese`]="{ item }">
              <v-icon
                medium
                style="align: center"
                @click="editarAnamnese(item)"
              >
                mdi-account-edit-outline
              </v-icon>
            </template>-->
            <template v-slot:[`item.avaliacao`]="{ item }">
              <v-icon medium @click="editarAvaliacao(item)" :color="getAvaliacaoColor(item.meses_avaliacao)">
                {{ item.meses_avaliacao === -1 ? 'mdi-clipboard-plus-outline' : 'mdi-clipboard-check-outline' }}
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-spacer>
                <div>
                  <br />
                </div>
              </v-spacer>
              <v-alert
                :value="true"
                type="info"
                icon="mdi-alert-circle-outline"
                color="warning"
                dark
              >
                Nenhum aluno encontrado.
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="dialogCompose"
            width="500">
    <v-card>
      <v-card-title class="headline" primary-title>
        Pagamentos
        </v-card-title>
        <v-card-text class="pa-5">                
          <!-- <span>{{ pagamentos }}</span> -->
            <v-data-table
              :headers="cabecalho"
              :items="pagamentos"
              :hide-default-footer="true"
            >
              <template v-slot:[`item.previsao`]="{ item }">            
                {{  item.previsao ? formatarData(item.previsao) : '' }}
              </template>
              <template v-slot:[`item.pagamento`]="{ item }">            
                {{  item.pagamento ? formatarData(item.pagamento) : '' }}              
              </template>
            </v-data-table>
        </v-card-text>
                <v-card-actions class="pa-5">
                    <v-btn class="ml-auto" @click="fecharModal()" outlined color="primary">Fechar</v-btn>
                </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialog"  max-width="500px">
      <v-card>
          <v-toolbar
          color="primary"
          dark
          >{{ tituloModal }}</v-toolbar>
          <v-card-text>
          <!-- <div class="text-h2 pa-12">Hello world!</div> -->
          <!-- <div v-for="aluno in this.alunos" :key="aluno.id" class="ma-2">
              {{ aluno.nome }}
          </div>-->
          <div class="ma-2">
              {{ subtituloModal }}
          </div>            
          <div v-if=obs_aluno class="ma-2" style="white-space: pre-line;">
            <b>Aluno</b> {{'\n' + obs_aluno }}
          </div> 
          <div v-if=obs_agenda class="ma-2" style="white-space: pre-line;">
            <b>Agenda:</b> {{ '\n' + obs_agenda }}
          </div> 
          <div v-if=obs_contrato class="ma-2" style="white-space: pre-line;">
            <b>Contrato:</b> {{ '\n' + obs_contrato }}
          </div> 
          </v-card-text>
          <v-card-actions class="justify-end">
          <v-btn
              text
              @click="fechaModal()"
          >Close</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</div>
</template>


<script>
import _alunos from "../../services/aluno/aluno-servico.js";
import _treinador from "../../services/treinador/treinador-servico.js";
import _academia from "../../services/academia/academia-servico.js";
import AppBar from "../../components/AppBar.vue";
import _utils from '../../utils/utils.js'
import _situacao_aluno from "../../models/situacao-aluno.js";

export default {
  components: {
    AppBar,
  },

  props: {},

  data() {
    return {
      headers: [
        // { text: "Código", value: "id" },
        { text: "Nome", align: "start", value: "nome" },
        { text: "Treinador", value: "treinador" },
        { text: "Produto", value: "produto" },
        { text: "Situação", value: "situacao_aluno" },
        { text: "Data Inicial", value: "data_inicial" },
        { text: "Contrato", value: "contrato_periodo" },
        // { text: "Contrato - Início", value: "contrato_ini" },
        // { text: "Contrato - Final", value: "contrato_fin" },            
        {
          text: "Valor",
          value: "valor",
          align: "center",
          sortable: false,
        },
        {
          text: "Parcelas",
          value: "parcelas",
          align: "center",
          sortable: false,
        },
        {
          text: "Situação",
          value: "situacao",
          align: "center",
          sortable: false,
        },

        {
          text: "Cadastro",
          value: "cadastro",
          align: "center",
          sortable: false,
        },

        {
          text: "Observações",
          value: "observacoes",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Contrato",
        //   value: "contrato",
        //   align: "center",
        //   sortable: false,
        // },
        // { text: "Agenda", value: "agenda", align: "center", sortable: false },
        // {
        //   text: "Anamense",
        //   value: "anamnese",
        //   align: "center",
        //   sortable: false,
        // },
        {
          text: "Avaliação",
          value: "avaliacao",
          align: "center",
          sortable: false,
        },
      ],
      cabecalho: [
        // { text: "Código", value: "id" },
        { text: "Vencimento", value: "previsao" },
        { text: "Pagamento", value: "pagamento" },
        { text: "Valor", value: "valor" },
      ],
      alunos: [],
      treinadores: [],
      treinador: "",
      academias: [],
      academia: "",
      nome: "",
      dados: [],
      todos: false,
      dialogCompose: false,
      filtros: [],
      pagos: "",
      ativos: true,
      pagamentos: [],
      pago: true,
      admin: true,
      dialog: false,
      tituloModal: "",
      subtituloModal: "",
      obs_aluno: "",
      obs_agenda: "",
      obs_contrato: "",
      produtos: [ "Academia", "Fithit"],
      produto: "",
      situacoes: _situacao_aluno,
      situacao: 1,//Ativo
      avaliacaoFiltro: null,
      avaliacaoOpcoes: [
        { text: 'Sem Avaliação', value: -1 },
        { text: 'Menos de 1 Mês', value: 0 },
        { text: 'Até 1 Mês', value: 1 },
        { text: 'Até 2 Meses', value: 2 },
        { text: 'Mais de 2 Meses', value: 3 },
        { text: 'Todos', value: null }
      ],
    };
  },

  computed: {
    currentRouteName() {
        return this.$route.name
    }
  },

  created() {    
    if (localStorage.dados) {
      this.dados = JSON.parse(localStorage.dados);
    } else {
      this.$router.replace({ name: "login" });
    }
    if (this.dados.admin == false) {
      this.$router.replace({ name: "treinadoralunos" });
    }
  },

  mounted() {
    if (localStorage.dados) {
      this.dados = JSON.parse(localStorage.dados);
    }
    if (this.dados["id"] == 636 || this.dados["id"] == 827) {
        this.admin = false
    }

    _treinador.listarTreinadoresAtivos().then((response) => {
      response.forEach((treinador) => {
        this.treinadores.push({
          id: treinador.id,
          nome: treinador.nome,
        });
      });
    });

    if (this.admin == true)
    {
      _academia.listarAcademias().then((response) => {
        response.forEach((academia) => {
          this.academias.push({
            id: academia.id,
            nome: academia.nome,
          });
        });
      });
    }
    else {
      this.academias.push({
            id: 2,
            nome: "Let's Move II",
          });
      this.academia = 2
    }
    
    if (this.$route.params.novos == true)
      this.listarAlunos(this.$route.params.novos);
    else if (this.currentRouteName == "alunos-desafio")
      this.listarAlunos(false, true);
    else
      this.listarAlunos();
  },

  methods: {
    abrirModal(dado) {        
      if (dado !== null) {        
        //console.log(JSON.parse(JSON.stringify(dado.pagmento_json)))
        //this.pagamentos = [JSON.parse(JSON.stringify(dado.pagmento_json))];
        this.pagamentos = [dado.pagmento_json]
        // const pagamentoJson = dado.pagmento_json;
        // console.log(pagamentoJson);
        // const valor = pagamentoJson.valor;        
        // console.log(valor)
      }
        this.dialogCompose = true
    },    
    fecharModal() {
        this.dialogCompose = false
    },
    async abreModal(nome, treinador, obs_aluno, obs_agenda, obs_contrato){
            this.tituloModal = "Observações"
            this.subtitulo =  nome + " | " + treinador
            this.obs_aluno = obs_aluno
            this.obs_agenda = obs_agenda
            this.obs_contrato = obs_contrato
            this.dialog = true
            //this.alunos = await this.buscaAlunos(treinador, dia_semana, horario)            
        },
        fechaModal()
        {
            this.dialog = false
            this.tituloModal = ""
            
        },
    listarAlunos(novos, experiencia) {
      this.filtros = {
          nome: this.nome,
          ativos: this.todos == true ? false : true,
          treinador_id: this.treinador == "" ? 0 : this.treinador,
          academia_id: this.academia == "" ? 0 : this.academia,
          produto: this.produto == "Academia" ? 0 : this.produto == "Fithit" ? 1 : null,
          situacao: this.situacao == "" ? null : this.situacao,
          avaliacao: this.avaliacaoFiltro === null ? null : this.avaliacaoFiltro,
          //data_acesso: null,
          //acesso: false,
          //data_inicial: _utils.formatarData(this.data_inicial),        
          //data_final: this.data_final == "" ? null : _utils.formatarData(this.data_final),
        };
        console.log(this.filtros)

        if (novos == true)
          this.filtros.precadastro = 'true';

        if (experiencia == true)
          this.filtros.experiencia = 'true';

      console.log(this.filtros)

      _alunos.listarAlunosAtivos(this.filtros).then((response) => {
          this.alunos = response;
        });
      // if (this.nome) {
      //   _alunos.buscarAlunoNome(this.nome).then((response) => {
      //     this.alunos = response;
      //   });
      // } else if (this.todos == false) {
      //   _alunos.listarAlunosAtivos(this.filtros).then((response) => {
      //     // console.log(response)
      //     this.alunos = response;
      //   });
      // } else { 
      //   _alunos.listarAlunos().then((response) => {
      //     this.alunos = response;
      //   });
      // }
    },
    editarAluno(aluno) {
      this.$router.push({ name: "cadastro", params: { id: aluno.usuario_id, tipo: 'Aluno' } });
    },
    editarContrato(aluno) {
      this.$router.push({
        name: "contrato",
        component: aluno,
        params: { aluno: aluno },
      });
    },
    editarAgenda(aluno) {
      this.$router.push({ name: "agendaaluno", params: { aluno: aluno } });
    },
    editarAnamnese(aluno) {
      this.$router.push({ name: "anamnese", params: { aluno: aluno } });
    },
    editarAvaliacao(aluno) {
      //this.$router.push({name:'avaliacao', params: {aluno: aluno}})
      this.$router.push({ name: "lista-avaliacao", params: { aluno: aluno } });
    },
    rota(rota) {
      //this.$router.replace({ name: rota });
      this.$router.push({ name: rota, params: { tipo: 'Aluno' } });
    },
    formatarData(data)
    {
        if (data != null)
          return _utils.formatarDataTela(data);
    },
    // getSituacao(situacao) {
    //         return _situacao_aluno[situacao].text;
    //     },
    limparCampos()
    {
      this.nome = '',
      this.treinador = '',
      this.academia = '',
      this.produto = '',
      this.situacao = '',
      this.listarAlunos()
    },
    getSituacao(situacao) {      
      return _situacao_aluno[situacao-1].text;
    },

    getAvaliacaoColor(mesesAvaliacao) {
      if (mesesAvaliacao < 0) {
        return 'black'; // Cor para 0 meses (opcional, ajuste se necessário)
      } else if (mesesAvaliacao === 0) {
        return 'green'; // Verde para até 1 mês
      } else if (mesesAvaliacao === 1) {
        return 'blue'; // Verde para até 1 mês
      } else if (mesesAvaliacao === 2) {
        return 'yellow'; // Amarelo para 2 meses
      } else {
        return 'red'; // Vermelho para mais de 2 meses
      }
    },
    //...mapActions({
    //   mostrarCarregando: "carregando/mostrarCarregando",
    //   mostrarMensagem: "mensagem/mostrarMensagem",
    //}),
  },
};
</script>