import axios from "axios"

const api = axios.create({  
  //baseURL: "https://ferramentas.icaro.fit/ferramentas/",
  //baseURL: "",
  baseURL:  process.env.NODE_ENV !== "production" 
                ? "https://localhost:7171/"
                : "https://ferramentas.icaro.fit/ferramentas/",      
  headers: {
    Accept: "application/json",
    Content: "application/json",
    "content-type": "application/json",
  },
})

export default api