import api from "../api/icaro"

const dre = {

  listar(dre_id) {
    return new Promise((resolve, reject) => {
      api
        .get(`dre/lancamento/?dre_id=${dre_id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(erro => {
          reject(erro)
        })
    })
  },

  salvar(dreLancamento) {
    return new Promise((resolve, reject) => {
      api
        .post("dre/lancamento/", dreLancamento)
        .then(response => {
          resolve(response.data)
        })
        .catch(erro => {
          reject(erro)
        })
    })
  },

  deletar(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`dre/lancamento/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(erro => {
          reject(erro)
        })
    })
  },

}

export default dre
