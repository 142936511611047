<template>           
  <v-container fluid>
    <AppBar titulo="" destino="dashboard" />
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn          
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>      
    </v-snackbar>    
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-toolbar color="orange" dense>
            <v-toolbar-title>Usuários</v-toolbar-title>    
          </v-toolbar>  
          <v-divider></v-divider>
          <br>
        <v-card>                  
          <v-card-title>
            Filtros
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Nome"
              outlined
              v-model="nome"              
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue" dark v-on:click="listarUsuarios()">Buscar <v-icon class="ml-2">mdi-magnify</v-icon></v-btn>
            <!-- <v-btn color="#ccc" dark v-on:click="limpar()">Limpar</v-btn> -->
            <v-btn class="ml-auto" color="#FF562B" dark v-on:click="rota('cadastro')">Cadastrar <v-icon class="ml-2">mdi-plus</v-icon> </v-btn> 
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <h3 class="mb-2">Lista de usuários</h3>
        <v-card>        
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="usuarios"
              :items-per-page="10"              
              :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                    'items-per-page-text':'Itens por página'
              }"
            >
            <template v-slot:[`item.cadastro`]="{ item }">
                <v-icon
                  medium
                  class="mr-2"
                  @click="editarUsuario(item)"
                >
                  mdi-pencil-outline
                </v-icon>
            </template>
            <template v-slot:[`item.relacionamento`]="{ item }">
                <v-icon v-if="item.relacionamento === null"
                  medium
                  class="mr-2"
                  @click="cadastrarTreinador(item)"
                  title="Treinador"
                >
                  mdi-human-male-board
                </v-icon>
                <v-icon v-if="item.relacionamento === 'Treinador'"
                  medium
                  class="mr-2"
                  @click="editarTreinador(item)"
                  title="Treinador"
                >
                  mdi-human-male-board
                </v-icon>
                <v-icon v-if="item.relacionamento === null"
                  medium
                  class="mr-2"
                  @click="cadastrarAluno(item)"
                  title="Aluno"
                >
                  mdi-walk
                </v-icon>
                <v-icon v-if="item.relacionamento === 'Aluno'"
                  medium
                  class="mr-2"
                  @click="editarAluno(item.relacionamento_id)"
                  title="Aluno"
                >
                  mdi-walk
                </v-icon>
                <!-- <span v-else>
                  {{ item.relacionamento }}
                </span> -->
            </template>
            <template v-slot:no-data>
                <center>
                  <div>
                    <v-progress-linear
                      color="orange lighten-2"
                      height="20"
                      
                      indeterminate
                      striped
                    ><span style="color: grey; font-size: 12px;">Carregando...</span></v-progress-linear>
                    <!-- <v-progress-circular indeterminate color="orange lighten-2" style="algn"></v-progress-circular> -->
                  </div>
                </center>                
              <!-- <v-alert
                :value="true"
                type="info"
                icon="mdi-alert-circle-outline"
                color="warning"
                dark
              >
                Nenhum usuário encontrado.
              </v-alert> -->
            </template>
            </v-data-table>
                </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _usuario from '../../../services/usuario/usuario-connect-servico.js'
import AppBar from '../../../components/AppBar.vue'
export default {
  components: {
    AppBar,
},  
  data () {
      return {  
        snackbar: false,
        text: 'Usuário não encontrado',
        headers: [
          {
            text: 'Nome',
            align: 'start',
            value: 'nome',
          },
          { text: 'E-mail', value: 'email' },
          { text: 'Telefone', value: 'telefone' },
          { text: 'Nascimento', value: 'nascimento' },
          { text: 'Instagram', value: 'instagram' },
          { text: 'Endereco', value: 'endereco' },
          { text: 'Relacionamento', value: 'relacionamento' },
          { text: 'Cadastro', value: 'cadastro', align: 'center', sortable: false },
        ],
        nome: "",
        usuarios: [],
      }
    },

  created() {
    // this.$vuetify.theme.dark = parseInt(this.$route.params.tema) === 1    
  },

  mounted() {    
    if (this.$route.params.novos == true)
      this.listarUsuarioNovos()
    else
      this.listarUsuarios()
  },

  methods: {  
    listarUsuarioNovos(){
      _usuario.listarUsuariosNovos().then(response => {
        this.usuarios = response        
        }).catch(error => {
            this.$toast.open({
                message: 'Erro ao listar usuários',
                type: 'error'                
            })
            console.log(error)
        })
    },
    listarUsuarios() {
        if (this.nome)
        _usuario.buscarUsuarios(this.nome).then(response => {
            this.usuarios = response
        }).catch(() => {            
            this.snackbar = true
            this.usuarios = []
        })
        else
        _usuario.listarUsuarios().then(response => {            
            this.usuarios = response            
        }).catch(error => {
            this.$toast.open({
                message: 'Erro ao listar usuários',
                type: 'error'                
            })
            console.log(error)
        })
    },
    editarUsuario(usuario) {
      this.$router.push({name:'cadastro', params: {id: usuario.id, tipo: 'Aluno'}})
    },   
    cadastrarTreinador(usuario)
    {      
      this.$router.push({name:'cadastrotreinadores', params: {id: usuario.id, origem: 'usuarios'}})
    },
    editarTreinador(usuario) {
      this.$router.push({name:'cadastrotreinadores', params: {id: usuario.id, origem: 'usuarios'}})
    },   
    cadastrarAluno(usuario)
    {      
      this.$router.push({name:'cadastroalunos', params: {id: usuario.id, origem: 'usuarios', tipo: 'cadastrar'}})
    },
    editarAluno(id) {      
      this.$router.push({name:'cadastroalunos', params: {id: id, origem: 'usuarios', tipo: ''}})
    },
    limpar() {
      this.dialogs.dialog = true;
      // this.nome = ""
      // this.usuarios = []
    }, 
    rota(rota) {        
        this.$router.replace({name:rota})
      },
    
    //...mapActions({
    //   mostrarCarregando: "carregando/mostrarCarregando",
    //   mostrarMensagem: "mensagem/mostrarMensagem",
    //}),
  },
}
</script>