import axios from "axios"

// const baseURL = (process.env.NODE_ENV || "development") !== "production"
//   ? "https://back-icaro-test.onrender.com/api/v1/"
//   : "https://api.icaro.fit/api/v1/";

// const baseURL ="https://back-icaro-test.onrender.com/api/v1/";
const baseURL ="https://api.icaro.fit/api/v1/";
  
const api = axios.create({  
  // baseURL: "http://localhost:8000/api/v1/",
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    Content: "application/json",
    "content-type": "application/json",
  },
})

export default api