/**
 * @typedef {Object} Aula 
 * @property {date} data
 * @property {boolean} presente
 * @property {number} situacao
 * @property {string} justificativa
 * @property {boolean} recuperada
 * @property {number} aluno_id
 * @property {number} treinador_id
 */
export default class Aula {
    constructor(
        data,
        presente,
        situacao,
        justificativa,
        recuperada,
        aluno_id,
        treinador_id,
    ) {
        this.data = data
        this.presente = presente
        this.situacao = situacao
        this.justificativa = justificativa
        this.recuperada = recuperada
        this.aluno_id = aluno_id
        this.treinador_id = treinador_id
    }
}