<template>
  <v-dialog v-model="dialogo" max-width="auto" persistent>
    <v-card class="">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center">
            Cadastrar
          </v-list-item-title>
        </v-list-item-content>
        <v-spacer />
        <v-btn icon @click="dialogo = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider class="mx-4" />
          <ValidationObserver ref="form" #default="{ handleSubmit }">
            <v-form
            @keyup.enter="handleSubmit(salvar)"
            @submit.prevent="handleSubmit(salvar)"
            >
            <v-card-text> 
                <v-row>
                <v-col cols="6">
                  <ValidationProvider
                    #default="{ errors }"
                    :rules="{ required: true }"
                    name="Ano"
                  >
                    <v-text-field
                      v-model="Dre.ano"
                      label="Ano"
                      :error-messages="errors[0]"
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    #default="{ errors }"
                    :rules="{ required: true }"
                    name="Mês"
                  >
                    <v-text-field
                      v-model="Dre.mes"
                      label="Mês"
                      :error-messages="errors[0]"
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions >
                  <v-btn color="#FF562B" type="submit" dark
                    >Salvar <v-icon class="ml-2">mdi-content-save</v-icon></v-btn
                  >
            </v-card-actions>
          </v-form>
        </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import {  ValidationProvider, ValidationObserver } from "vee-validate";

import _utils from "../../utils/utils.js";
import Dre from "../../models/dre.js";

import _dre from "../../services/financeiro/dre.js"

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      util: _utils,
      Dre: new Dre(),
    };
  },
  computed: {
    dialogo: {
      get() {
        this.buscar();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    buscar() {},

    salvar() {
      
      this.Dre.saldo_inicial = 0
      this.Dre.lucro = 0

      console.log(this.Dre)

      _dre.salvar(this.Dre).then(() => {
        this.fecharDialogo();
      });
    },

    fecharDialogo() {
      this.$root.$refs.ListaFinanceiro.listar();
      this.dialogo = false;
    },
  },
};
</script>