<template>
  <v-dialog v-model="dialogo" max-width="auto">
    <v-card class="">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center">
            Lista de Signatários do contrato/aluno:
            <span class="text-h5 ml-2">{{ assinatura.nomeenvelope }}</span>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-chip class="" 
        >{{ statusContrato }}
          </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-spacer />
        <v-btn icon @click="dialogo = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider class="mx-4" />
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="itensTabela"
          :items-per-page="10"
          class="elevation-0"
        >
          <template v-slot:[`item.Criacao`]="{ item }">
            {{ util.formatarDataTela(item.criacao) }}
          </template>
          <template v-slot:[`item.Status`]="{ item }">
            {{
              statusAssinaturaSignatario.filter(
                (x) => x.valor === item.Status
              )[0].descricao
            }}
          </template>
          <template v-slot:[`item.Link`]="{ item }">
            <v-btn
              icon
              id="idCodigoLink"
              @click="util.copiarAreaTransferencia('idCodigoLink', item.Link)"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider class="mt-12" />
    </v-card>
  </v-dialog>
</template>

<script>
import _ferramentas from "../../services/ferramentas/contrato/contrato.js";

import _utils from "../../utils/utils.js";

import _statusAssinatura from "../../enums/status-assinatura.js";
import _statusAssinaturaSignatario from "../../enums/status-assinatura-signatario.js";

export default {
  components: {},
  props: {
    value: Boolean,
    contratoId: Number,
  },
  data() {
    return {
      assinatura: {},
      itensTabela: [],
      headers: [
        { text: "Código", value: "id" },
        { text: "Nome", align: "start", value: "NomePessoa" },
        { text: "E-mail", align: "start", value: "Email" },
        { text: "Data Criação", value: "Criacao" },
        { text: "Status", value: "Status" },
        { text: "Link", value: "Link" },
      ],

      util: _utils,
      statusContrato: "",      
      statusAssinatura: _statusAssinatura,
      statusAssinaturaSignatario: _statusAssinaturaSignatario,
    };
  },
  computed: {
    dialogo: {
      get() {
        this.listar();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    listar() {
      _ferramentas.listarAssinaturas(this.contratoId).then((result) => {
        this.assinatura = result[0];
        this.itensTabela = JSON.parse(result[0].listasignatarios);
        this.statusContrato = this.statusAssinatura.filter((x) => x.valor === this.assinatura.status)[0].descricao
      });
    },

    fecharDialogo() {
      //   this.$root.$refs.AppListaPermissaoUsuario.listar()
      this.dialogo = false;
    },
  },
};
</script>