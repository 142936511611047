import api from "../api/icaro"
import utils from  "../../utils/utils.js"

const icaro = {
  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarAvaliacaoAluno(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/avaliacao/lista/${id}/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  buscarAvaliacaoAluno(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/avaliacao/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },


   /**
   * @param {Object} avaliacao
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
   baixarAvaliacaoAluno(avaliacao) {
    return new Promise((resolve, reject) => {
      api
        .post(`/avaliacao/baixar/`, avaliacao, { responseType: 'blob' })
        .then((response) => {
          const nomeCompleto = avaliacao.aluno;
          //const primeiroNome = nomeCompleto.split(' ')[0];
          const nomeSemAcentos = utils.removerAcentos(nomeCompleto);
          const data = utils.formatarDataTela(avaliacao.data);
          const nomeArquivo = `DNA_${data}_${nomeSemAcentos}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');          
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch((erro) => {
          reject(erro);
        });
    });
  },  

    /**
   * @param {Object} avaliacao
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
   cadastrarAvaliacao(avaliacao) {
    return new Promise((resolve, reject) => {
      api
        .post(`/avaliacao/`, avaliacao)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  atualizarAvaliacao(id, avaliacao) {
    return new Promise((resolve, reject) => {
      api
        .put(`/avaliacao/${id}`, avaliacao)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} aluno
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  deletarAvaliacao(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/avaliacao/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  }
  
}

export default icaro