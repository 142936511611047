<template>
    <div>
      <v-row>
        <v-col>
            <v-alert prominent type="success" v-if="novo && novos==1">            
              <v-row align="center">
                <v-col class="grow" v-if="novo && novos==1">
                  1 Novo usuário cadastrado!
                </v-col>
                <v-col class="grow" v-if="novo && novos>1">
                  {{ novos }} novos usuários cadastrados!
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="cadastro()">Cadastro</v-btn>
                </v-col>
              </v-row>
            </v-alert>                      
        </v-col>
      </v-row>
      <v-row>      
        <!-- <v-col cols="12" sm="4" md="4" lg="4">
          <app-card-numerador titulo="Usuários" icone="account-multiple" cor="rgb(255 86 43 / 90%)"
            fundo="rgb(255 86 43 / 50%)" :total="10" :possui-acao="false"></app-card-numerador>
        </v-col> -->
  
        <v-col cols="12" sm="6" md="6" lg="6">
          <app-card-numerador titulo="Treinadores" icone="weight-lifter" cor="rgb(125 241 61 / 90%)"
            fundo="rgb(125 241 61 / 50%)" :total="this.treinadores" :possui-acao="false"></app-card-numerador>
        </v-col>
  
        <v-col cols="12" sm="6" md="6" lg="6">
          <app-card-numerador titulo="Alunos" icone="account-group" cor="rgb(0 84 255 / 90%)" fundo="rgb(0 84 255 / 50%)"
            :total="this.alunos" :possui-acao="false"></app-card-numerador>
        </v-col>
  
        <v-col cols="6">
          <v-card min-height="200">
            <v-card-title>Alunos</v-card-title>
            <v-card-text class="py-0">
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Silva
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>   
              <br/>
              <br/>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Medianeira
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/><br/>
              <v-spacer></v-spacer>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"              
                >
                  Total
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/>
            </v-card-text>
          </v-card>
        </v-col>
  
        <v-col cols="6">
          <v-card min-height="200">
            <v-card-title>Entradas</v-card-title>
            <v-card-text class="py-0">
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Silva
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>   
              <br/>
              <br/>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Medianeira
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/><br/>
              <v-spacer></v-spacer>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"              
                >
                  Total
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/>
            </v-card-text>
          </v-card>
        </v-col>
  
        <v-col cols="6">
          <v-card min-height="200">
            <v-card-title>Saídas (Churn)</v-card-title>
            <v-card-text class="py-0">
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Silva
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>   
              <br/>
              <br/>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Medianeira
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/><br/>
              <v-spacer></v-spacer>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"              
                >
                  Total
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/>
            </v-card-text>
          </v-card>
        </v-col>
  
        <v-col cols="6">
          <v-card min-height="200">
            <v-card-title>Renovações</v-card-title>
            <v-card-text class="py-0">
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Silva
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>   
              <br/>
              <br/>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Medianeira
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/><br/>
              <v-spacer></v-spacer>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"              
                >
                  Total
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/>
            </v-card-text>
          </v-card>
        </v-col>
  
        <v-col cols="6">
          <v-card min-height="200">
            <v-card-title>LTV</v-card-title>
            <v-card-text class="py-0">
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Silva
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>   
              <br/>
              <br/>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Medianeira
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/><br/>
              <v-spacer></v-spacer>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"              
                >
                  Total
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/>
            </v-card-text>
          </v-card>
        </v-col>
  
        <v-col cols="6">
          <v-card min-height="200">
            <v-card-title>Tiket Médio (Mensalidades)</v-card-title>
            <v-card-text class="py-0">
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Silva
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>   
              <br/>
              <br/>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"
                >
                  Medianeira
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/><br/>
              <v-spacer></v-spacer>
              <v-row align="center" no-gutters>
                <v-col
                  class="text-h6"
                  cols="6"              
                >
                  Total
                </v-col>
  
                <v-col cols="6" class="text-h6 text-right">
                  <span>0</span>
                </v-col>
              </v-row>
              <br/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import AppCardNumerador from "../components/AppCardNumerador.vue";
  // import GraficoBarras from "./AppGraficoBarra.vue";
  import _usuario from '../services/usuario/usuario-servico.js'
  
  export default {
    components: {
      // GraficoBarras,
      AppCardNumerador,
    },
    data() {
      return {
        usuarios: [],
        alunos: 0,
        treinadores: 0,
        novo: false,
        novos: 0,
      };
    },
    
    mounted() {    
      this.listarUsuarios()        
    },  
  
    methods: {    
      listarUsuarios() {        
          _usuario.listarUsuariosAtivos().then(response => {
              this.usuarios = response
              this.totalUsuarios()
          }).catch(error => {
              this.$toast.open({
                  message: 'Erro ao listar usuários',
                  type: 'error'                
              })
              console.log(error)
          })
          _usuario.listarUsuariosNovos().then(response => {
            this.novo = true;
            this.novos = response.length
            // console.log(response.length)
          }).catch(() => {            
              console.log('não')
          }).catch(error => {
              this.$toast.open({
                  message: 'Erro ao listar usuários',
                  type: 'error'                
              })
              console.log(error)
          })
      },
          
      totalUsuarios() {
        var treinadores = 0;
        var alunos = 0;
  
        this.usuarios.filter((item) => {
          if (item.relacionamento == "Treinador")
            treinadores++;
          else if (item.relacionamento == "Aluno")
            alunos++;
        })
  
        this.treinadores = treinadores;
        this.alunos = alunos;
      },
  
      filtrarUsuarios() {
        let tempUsuarios = this.usuarios
  
        tempUsuarios = tempUsuarios.filter((item) => {
          return(item.relacionamento == "Treinador")
        })
  
        console.log(tempUsuarios)
        return tempUsuarios;
      },
  
      cadastro() {
        this.$router.push({ name: 'usuarios', params: {novos: true} });
      }
    },  
    //computed: {
      
    //},
  };
  </script>