<template>
    <v-card>                    
        <v-card-title class="justify-center">
            <h2>Treinos do dia</h2>
        </v-card-title>
        <v-card-subtitle class="text-center">
            <h3>{{ dia_mes }}</h3>
            {{dia_semana}}
        </v-card-subtitle>                    
        <v-card-text class="text-center">
            <!-- <i>Não há treinos hoje</i> -->            
        </v-card-text>    
        
    <v-stepper v-model="step" vertical v-if="carregado">
        <!-- eslint-disable-next-line vue/no-v-for-template-key -->
        <div v-for="(item, index) in agenda" :key="index">
                <v-stepper-step :step="index+1" editable @click="carregaAlunos(item.horario.substring(0, 5))">
                    {{ item.horario.substring(0, 5) }}
                </v-stepper-step>
                <v-stepper-content :step="index+1">
                    <span v-if="alunos_dia.length == 0"><i>Não há treinos neste horário</i></span>
                    <span v-else v-for="(aluno, indice) in alunos_dia" :key="indice">
                        {{ aluno.nome }} 
                    </span>
                </v-stepper-content>
        </div>        
        </v-stepper>
    </v-card>            
</template>

<script>
import _util from '../../utils/utils.js'
import _treinador from '../../services/treinador/treinador-servico.js'
import _alunos from '../../services/aluno/aluno-servico.js'

export default {
    data () { 
        return {
            dia_mes: "",
            dia_semana: "",
            diaSemana: "",
            dados: [],
            step: 0,
            agenda: [],
            alunos_dia: [],
            carregado: false,
        }
    },
    beforeMount() {
                if(localStorage.dados) {        
                    this.dados = JSON.parse(localStorage.dados)
                }
                this.getNow();
                this.buscaAlunosdia('05:30');
                this.buscaAgendaTreinador();
            },
    methods: {
                getNow() {
                    const today = new Date();
                    const yyyy = today.getFullYear();
                    let mm = today.getMonth() + 1; // Months start at 0!
                    let dd = today.getDate();

                    if (dd < 10) dd = '0' + dd;
                    if (mm < 10) mm = '0' + mm;

                    const formattedToday = dd + '/' + mm + '/' + yyyy;
                    
                    this.dia_mes = formattedToday
                    this.dia_semana = _util.diaSemana(today.getDay());
                    this.diaSemana = today.getDay() 
                },
                buscaAgendaTreinador(){
                    _treinador.buscarAgendaTreinadorDia(this.dados.relacionamento_id, this.diaSemana).then(response => {
                        //console.log(response)
                        this.agenda = response
                        localStorage.agenda = JSON.stringify(this.agenda);                                                
                    })
                },
                buscaAlunosdia(horario){
                    _alunos.buscarAlunosTreinadorDia(this.dados.relacionamento_id, this.diaSemana, horario).then(response => {
                        console.log(response)
                        this.alunos_dia = response
                        localStorage.alunos_dia = JSON.stringify(this.alunos_dia);
                        this.carregado = true
                        //console.log(this.isFetching)
                        //return this.alunos_dia
                    });
                },
                carregaAlunos(horario){
                    this.buscaAlunosdia(horario)
                },
                rota(rota) {        
                this.$router.replace({name:rota})
                }
            }
}
</script>
