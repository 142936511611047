<template>
  <div>
    <v-text-field
      v-model="campoConsulta"
      prepend-inner-icon="mdi-magnify"
      label="consultar"
      outlined
      dense
      single-line
      hide-details
      clearable
      class="mb-2"
    ></v-text-field>
    <v-data-table
      :headers="cabecalho"
      :items="cobrancas"
      class="elevation-0"
      :search="campoConsulta"
    >
      <template v-slot:[`item.data_vencimento`]="{ item }">
        <span>{{ formatarData(item.data_vencimento) }}</span>
      </template>
      <template v-slot:[`item.data_pagamento`]="{ item }">
        <span v-if="item.data_pagamento">{{
          formatarData(item.data_pagamento)
        }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="RetornaEnumStatus(item.status).cor">
          <small>{{ RetornaEnumStatus(item.status).descricao }}</small>
        </v-chip>
      </template>
      <template v-slot:[`item.tipo`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.tipo" v-bind="attrs" v-on="on">
              mdi-{{ RetornaFormaPagamento(item.tipo).icone }}
            </v-icon>
          </template>
          <span>{{ RetornaFormaPagamento(item.tipo).descricao }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.identificador_asaas`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-if="item.link_pagamento"
              :disabled="item.status !== 1 && item.status !== 4"
              @click="copiarParaAreaTranferencia(item.link_pagamento)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <span>Link para pagamento</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-if="item.identificador_asaas"
              :disabled="item.status !== 1 && item.status !== 4"
              @click="cobrancaSelecionada = item"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-cash-check</v-icon>
            </v-btn>
          </template>
          <span>Definir como pago em dinheiro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="
                item.tipo === 3 ||
                (item.status !== 1 && item.status !== 4) ||
                !item.identificador_asaas
              "
              @click="consultarQrCode(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-btn>
          </template>
          <span>Visualizar QrCode e Copia e Cola</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="confirmacao(item.academia_id, item.id)" :disabled="naoPermitirGerarNotaFiscal(item)" v-bind="attrs" v-on="on">
              <v-icon>mdi-file-move</v-icon>
            </v-btn>
          </template>
          <span>Gerar Nota Fiscal</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar.mostrar" :color="snackbar.cor">
      {{ snackbar.texto }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.mostrar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-if="qrcode" v-model="qrcode" max-width="500px">
      <v-card>
        <v-toolbar color="primary" dark
          >QrCode para pagamento
          <v-spacer></v-spacer>
          <v-btn icon @click="fecharModalQrCode()">
            <v-icon>mdi mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="descricaoQrCode"
                label="Descrição"
                outlined
                hide-details
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="pessoaQrCode"
                label="Usuário"
                outlined
                hide-details
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-img
              v-if="qrcode"
              :src="imagemQrCode(qrcode.imagemBase64)"
              alt="Imagem"
            />
          </v-row>
          <template>
            <v-text-field
              class="mt-8"
              v-model="qrcode.copiaCola"
              label="copia e cola"
              readonly
            >
              <template #append>
                <v-btn
                  icon
                  @click="
                    copiarParaAreaTranferencia(
                      qrcode.copiaCola,
                      'copia e cola pix'
                    )
                  "
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </template>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="error" @click="fecharModalQrCode()"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="cobrancaSelecionada"
      v-model="cobrancaSelecionada"
      max-width="500px"
    >
      <v-card>
        <v-toolbar color="primary" dark
          >Definir como recebida cobrança em dinheiro
          <v-spacer></v-spacer>
          <v-btn icon @click="cobrancaSelecionada = null">
            <v-icon>mdi mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <ValidationObserver ref="form" #default="{ handleSubmit }">
            <v-form
              @keyup.enter="handleSubmit(salvar)"
              @submit.prevent="handleSubmit(salvar)"
            >
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="menuDatePickerI"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <ValidationProvider
                          #default="{ errors }"
                          name="Data Pagamento"
                        >
                          <v-text-field
                            v-model="recebimento.dataPagamento"
                            label="Data Pagamento"
                            prepend-inner-icon="mdi-calendar"
                            :error-messages="errors[0]"
                            outlined
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          />
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="dataPagamento"
                        :active-picker.sync="ativarDatePicker"
                        min="1900-01-01"
                        locale="pt-BR"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="Valor Pago"
                      v-money="valorConfig"
                    >
                      <v-text-field
                        v-model="recebimento.valorPago"
                        label="Valor Pago"
                        :error-messages="errors[0]"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="recebimento.notificarCliente"
                      label="Notificar o Cliente"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form> </ValidationObserver
          >'
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="error" @click="cobrancaSelecionada = null">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="definirPagoEmDinheiro()">
            Salvar
            <v-icon class="ml-2">mdi mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppConfirma :dialogs="dialogs" @resposta="respostaGerarNota" />
  </div>
</template>
    
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import _asaas from "../../services/asaas";

import _formaPagamento from "../../enums/forma-pagamento";
import _statusCobranca from "../../enums/status-cobranca";

import util from "../../utils/utils.js";

import AppConfirma from "../../components/AppConfirma.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AppConfirma,
  },
  props: {
    cobrancas: { required: false, type: Array, default: null },
  },
  data() {
    return {
      dialogs: {
        dialog: false,
        titulo: "",
        tipo: "",
      },
      snackbar: {
        mostrar: false,
        texto: "",
        cor: "",
      },

      recebimento: {
        dataPagamento: null,
        valorPago: 0,
        notificarCliente: false,
      },

      qrcode: null,
      pessoaQrCode: null,
      descricaoQrCode: null,

      cobrancaSelecionada: null,
      campoConsulta: null,

      dataPagamento: null,
      menuDatePickerI: false,

      ativarDatePicker: null,

      valorConfig: {
        // Configuration options for v-money
        decimal: ",",
        // Decimal separator
        thousands: ".",
        // Thousands separator
        prefix: "R$ ",
        // Currency symbol
        precision: 2,
        // Number of decimal places
        masked: false,
        // Do not show placeholders for unfilled digits
        allowBlank: false,
        // Do not allow an empty value
      },

      cabecalho: [
        {
          text: "Descrição",
          align: "start",
          sortable: true,
          value: "descricao",
        },
        {
          text: "Usuário",
          align: "start",
          sortable: true,
          value: "usuario",
        },
        {
          text: "Valor",
          align: "center",
          sortable: true,
          value: "valor_pago",
        },
        {
          text: "Valor Líquido",
          align: "center",
          sortable: true,
          value: "valor_liquido",
        },
        {
          text: "Valor Original",
          align: "center",
          sortable: true,
          value: "valor_original",
        },
        {
          text: "Vencimento",
          align: "center",
          sortable: true,
          value: "data_vencimento",
        },
        {
          text: "Pagamento",
          align: "center",
          sortable: false,
          value: "data_pagamento",
        },
        {
          text: "Situação",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Forma",
          align: "center",
          sortable: false,
          value: "tipo",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          value: "identificador_asaas",
        },
      ],
    };
  },
  mounted() {
    this.recebimento.dataPagamento = util.converterFormatoPtBr(new Date().toISOString());
  },

  watch: {
    dataPagamento() {
      this.recebimento.dataPagamento = util.converterFormatoPtBr(
        this.dataPagamento
      );
    },
  },
  methods: {
    notificar(texto, cor = "primary") {
      this.snackbar.cor = cor;
      this.snackbar.texto = texto;
      this.snackbar.mostrar = true;
    },

    confirmacao(idAcademia, idCobranca) {
      this.dialogs.titulo = "Nota Fiscal";
      this.dialogs.mensagem =
        "Esse processo não pode ser revertido nem cancelado, deseja gerar nota fiscal para essa cobrança de forma manual?";
      this.dialogs.tipo = `${idAcademia};${idCobranca}`;
      this.dialogs.dialog = true;
    },

    naoPermitirGerarNotaFiscal(cobranca) {
      return cobranca.nota_fiscal || new Date(cobranca.data_pagamento) < new Date(2024, 7, 1);
    },

    respostaGerarNota(resposta) {
      if (resposta[1]) {
        const identificadores = resposta[0].split(";")

        console.log(resposta);

        _asaas
        .gerarNotaFiscal(
          identificadores[0],
          identificadores[1]
        )
        .then(() => {
          this.notificar(
            "Nota agendada com sucesso!",
            "success"
          );
        })
        .catch((erro) => {
          console.log(erro);
          
          this.cobrancaSelecionada = null;
          this.notificar(
            "Erro ao tentar agendar nota!",
            "error"
          );
        });
      } else {
        this.dialogs.dialog = false;
      }
    },

    imagemQrCode(qrcode_imagem) {
      return "data:image/png;base64," + qrcode_imagem;
    },

    consultarQrCode(cobranca) {
      _asaas.buscarQrCode(cobranca.academia_id, cobranca.identificador_asaas).then((retorno) => {
        this.pessoaQrCode= cobranca.usuario
        this.descricaoQrCode = cobranca.descricao
        this.qrcode = retorno
      });
    },

    fecharModalQrCode(){
      this.qrcode = null
      this.pessoaQrCode = null
      this.descricaoQrCode = null
    },

    definirPagoEmDinheiro() {
      this.recebimento.dataPagamento = util.formatarData(
        this.recebimento.dataPagamento
      );
      this.recebimento.valorPago = parseFloat(
        this.recebimento.valorPago.replace(/[^\d-,]/g, "").replace(",", ".")
      );

      _asaas
        .recebidoEmDinheiro(
          this.cobrancaSelecionada.academia_id,
          this.cobrancaSelecionada.identificador_asaas,
          this.recebimento
        )
        .then(() => {
          this.cobrancaSelecionada = null;
          this.notificar(
            "Cobrança definida como recebida em dinheiro!",
            "success"
          );
        })
        .catch(() => {
          this.cobrancaSelecionada = null;
          this.notificar(
            "Erro ao definir cobrança como recebida em dinheiro!",
            "error"
          );
        });

        this.recebimento.dataPagamento = util.converterFormatoPtBr(new Date().toISOString());
        this.recebimento.valorPago = 0
        this.recebimento.notificarCliente = false
    },

    formatarData(data) {
      return new Date(data).toLocaleDateString("pt-BR");
    },

    RetornaFormaPagamento(forma) {
      return _formaPagamento.find((F) => F.valor == forma);
    },

    RetornaEnumStatus(status) {
      return _statusCobranca.find((F) => F.valor == status);
    },

    copiarParaAreaTranferencia(copiar, conteudo = "link") {
      // Verifica se o navegador suporta a API de Clipboard
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(copiar)
          .then(() => {
            this.notificar(
              conteudo + " copiado para a área de transferência!",
              "success"
            );
          })
          .catch(() => {
            this.notificar("Falha ao copiar o " + conteudo, "warning");
          });
      } else {
        this.notificar(
          "Seu navegador não suporta a funcionalidade de copiar para a área de transferência.",
          "grey"
        );
      }
    },
  },
};
</script>
  