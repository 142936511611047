<template>
    <div>
        <v-container fluid>
            <AppBar titulo="" destino="dashboard" />
            <v-toolbar color="orange" dense>
                <v-toolbar-title>Vamo Mexe</v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <br />
            <v-row align="center" justify="center">
                <v-col col="12" md="12" xl="12" xs="12" sm="12">
                    <v-card>
                        <v-card-title> Filtros </v-card-title>
                        <v-card-text>
                        
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="blue" dark v-on:click="buscar()"
                            >Buscar <v-icon class="ml-2">mdi-magnify</v-icon></v-btn>
                            <v-btn color="#ccc" dark @click="limparCampos()">Limpar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
import AppBar from "../../components/AppBar.vue";

export default {
    components: {
        AppBar,
    },

    props: {},

    data() {
        return {
        }
    },

    methods: {
        buscar(){

        },
        limparCampos(){

        }
    }    
}
</script>
