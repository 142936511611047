<template>
  <v-container fluid>
    <AppConfirma :dialogs="dialogs" @resposta="getResposta"></AppConfirma>    
    <AppAlerta :snack="snack" />
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <ValidationObserver ref="form" #default="{ handleSubmit }">
            <v-form
              @keyup.enter="handleSubmit(salvar)"
              @submit.prevent="handleSubmit(salvar)"
            >
              <v-card-text>
                <v-row>
                  <v-col col="12" md="12" xl="12">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="nome"
                    >
                    <v-combobox
                        v-model="pessoa"
                        :items="usuarios"
                        label="Pessoa"
                        :error-messages="errors[0]"
                        item-text="nome"
                        item-value="id"
                        outlined
                        :disabled="pessoaDesativada"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12" md="6" xl="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="data_inicial"
                      v-mask="'##/##/####'"
                    >
                      <v-text-field
                        v-model="data_inicial"
                        label="Data Inicial"
                        color="#FF562B"
                        :error-messages="errors[0]"
                        outlined
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col col="12" md="6" xl="6">
                    <v-text-field
                      v-model="data_final"
                      label="Data Final"
                      color="#FF562B"
                      v-mask="'##/##/####'"
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-row>
                  <v-col col="12" md="4" xl="4">
                    <v-btn @click="cancelar" color="grey" width="100%" dark
                      ><v-icon class="mr-2">mdi-arrow-left</v-icon>Cancelar
                    </v-btn>
                  </v-col>
                  <v-col col="12" md="4" xl="4">
                    <v-btn
                      color="red"
                      width="100%"
                      dark
                      @click="excluir()"
                      :disabled="isDisabled"
                      >Excluir<v-icon class="ml-2">mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col col="12" md="4" xl="4">
                    <v-btn color="#FF562B" width="100%" dark type="submit"
                      >Salvar
                      <v-icon class="ml-2">mdi-content-save</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import _usuario from "../services/usuario/usuario-servico.js";
import _treinador from "../services/treinador/treinador-servico.js";
import _utils from "../utils/utils.js";
import AppAlerta from "../components/AppAlerta.vue";
import AppConfirma from "../components/AppConfirma.vue";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AppAlerta,
    AppConfirma,
  },

  data() {
    return {
      dialogs: {
        dialog: false,
      },
      snack: {
        snackbar: false,
        texto: "",
        cor: "",
      },
      id: 0,
      usuarios: [],
      treinador: [],
      pessoa: "",
      data_inicial: "",
      data_final: "",
      isDisabled: false,
      pessoaDesativada: false,
      origem: "treinadores",
    };
  },

  mounted() {
    _usuario.listarUsuariosSemRelacionamento("treinadores").then((response) => {
      response.forEach((usuario) => {
        this.usuarios.push({
          id: usuario.id,
          nome: usuario.nome,
        });
      });
    });

    if (this.$route.params.id != null) {
      if (this.$route.params.origem == "usuarios") {
        this.origem = "usuarios";
        this.pessoa = this.$route.params.id;
        this.isDisabled = true;
        this.pessoaDesativada = true;
      } else {
        this.buscar(this.$route.params.id);
        this.id = this.$route.params.id;
      }
    }
  },

  methods: {
    salvar() {
      this.treinador = {
        usuario_id: this.pessoa,
        data_inicial: _utils.formatarData(this.data_inicial),
        data_final:
          this.data_final == "" ? null : _utils.formatarData(this.data_final),
      };

      if (this.id == 0) {
        this.salvarTreinador();
      } else {
        this.atualizar();
      }
    },
    salvarTreinador() {
      _treinador.cadastrartreinador(this.treinador).then((response) => {
        this.id = response.id;
        this.snack.texto = "Treinador cadastrado com sucesso!";
        this.snack.snackbar = true;
        setTimeout(() => {
          this.redireciona();
        }, 1000);
      });
    },
    atualizar() {
      _treinador.atualizartreinador(this.id, this.treinador).then(() => {
        this.snack.texto = "Treinador atualizado com sucesso!";
        this.snack.snackbar = true;
        setTimeout(() => {
          this.redireciona();
        }, 1000);
      });
    },
    buscar(id) {
      _treinador.buscartreinador(id).then((response) => {
        this.treinador = response;
        this.pessoa = this.treinador.usuario_id;
        this.data_inicial = this.treinador.data_inicial;
        this.data_final = this.treinador.data_final;
      });
    },
    excluir() {
      this.dialogs.dialog = true;
    },
    getResposta(value) {
      this.resposta = value;
      if (this.resposta == true) {
        _treinador.deletartreinador(this.id).then(() => {
          this.snack.texto = "Treinador excluido com sucesso!";
          this.snack.snackbar = true;
          setTimeout(() => {
            this.redireciona();
          }, 1000);
        });
      }
    },
    cancelar() {
      this.redireciona();
    },
    redireciona() {
      if (this.origem == "usuarios") {
        this.$router.push("/usuarios");
      } else {
        this.$router.push("/treinadores");
      }
    },
  },
};
</script>