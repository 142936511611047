<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="font-weight-medium">Estatísticas Gerais</h1>
        <!-- <h4 class="ml-3 grey--text">Período de 01/03/2024 à 07/03/2024</h4>         -->
      </v-col>
      <v-col cols="12" md="5">
        <v-menu
          v-model="menuDatePickerI"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <ValidationProvider #default="{ errors }" name="Data Inicial">
              <v-text-field
                v-model="data_inicial"
                label="Data Inicial"
                prepend-inner-icon="mdi-calendar"
                :error-messages="errors[0]"
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </ValidationProvider>
          </template>
          <v-date-picker
            v-model="dataInicial"
            :active-picker.sync="ativarDatePicker"
            min="1900-01-01"
            locale="pt-BR"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" md="5">
        <v-menu
          v-model="menuDatePickerF"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <ValidationProvider #default="{ errors }" name="Data final">
              <v-text-field
                v-model="data_final"
                label="Data final"
                prepend-inner-icon="mdi-calendar"
                :error-messages="errors[0]"
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </ValidationProvider>
          </template>
          <v-date-picker
            v-model="dataFinal"
            :active-picker.sync="ativarDatePicker"
            min="1900-01-01"
            locale="pt-BR"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="primary" height="55" width="100%" @click="listarEstatisticas()"
          ><v-icon>mdi-magnify</v-icon>Pesquisar</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="listaDados.length > 0">
      <v-col cols="12">
        <v-card color="#FBFBFB" elevation="0">
          <v-card-title class="text-h4 font-weight-medium">
            <v-icon class="mr-2 text-h4">mdi-account-multiple</v-icon> Alunos
          </v-card-title>
          <v-card-text class="">
            <v-row>
              <v-col cols="12" md="12" lg="4">
                <v-card                  
                  class="rounded-md"
                  elevation="0"
                  color="#EBF6EF"
                  @click="popularTabela()"
                >
                  <v-card-text class="ml-4 d-flex">
                    <v-icon v-if="cardSelecionado === 'Todos'" color="green" style="position: absolute; top: 2px; right: 3px;" size="16">mdi-radiobox-marked</v-icon>
                    <div>
                      <div class="text-body-1 font-weight-bold">Total</div>
                      <div class="text-h3 font-weight-bold">{{(this.listaDados[0].alunos.length + this.listaDados[1].alunos.length)}}</div>
                    </div>
                    <div class="text-center">
                      <app-chip                        
                        nome="entradas"
                        porcentagem="10"
                        :valor="(this.listaDados[0].entradas + this.listaDados[1].entradas)"
                      ></app-chip>

                      <app-chip                        
                        nome="renovações"
                        porcentagem="10"
                        :valor="this.listaDados[0].renovacoes + this.listaDados[1].renovacoes"
                      ></app-chip>

                      <app-chip                        
                        nome="transferências"
                        porcentagem="10"
                        :valor="this.listaDados[0].transferencias + this.listaDados[1].transferencias"
                      ></app-chip>

                      <app-chip
                        :saida="true"
                        nome="saídas"
                        porcentagem="10"
                        :valor="this.listaDados[0].saidas + this.listaDados[1].saidas"
                      ></app-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="12" lg="4">
                <v-card                  
                  class="rounded-md"
                  elevation="0"
                  color="#EAF6FC"
                  @click="popularTabela(0, 'Silva')"
                >
                  <v-card-text class="ml-4 d-flex">
                    <v-icon v-if="cardSelecionado === 'Silva'" color="green" style="position: absolute; top: 2px; right: 3px;" size="16">mdi-radiobox-marked</v-icon>
                    <div>
                      <div class="text-body-1 font-weight-bold">Silva</div>
                      <div class="text-h3 font-weight-bold">
                        {{ listaDados[0].total }}
                      </div>
                    </div>
                    <div class="text-center">
                      <app-chip                        
                        nome="entradas"
                        porcentagem="10"
                        :valor="listaDados[0].entradas"
                      ></app-chip>

                      <app-chip                        
                        nome="renovações"
                        porcentagem="10"
                        :valor="listaDados[0].renovacoes"
                      ></app-chip>

                      <app-chip                        
                        nome="transferências"
                        porcentagem="10"
                        :valor="listaDados[0].transferencias"
                      ></app-chip>

                      <app-chip
                        :saida="true"
                        nome="saídas"
                        porcentagem="10"
                        :valor="listaDados[0].saidas"
                      ></app-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="12" lg="4">
                <v-card                  
                  class="rounded-md"
                  elevation="0"
                  color="#F0ECFD"
                  @click="popularTabela(1, 'Medianeira')"
                >
                  <v-card-text class="ml-4 d-flex">
                    <v-icon v-if="cardSelecionado === 'Medianeira'" color="green" style="position: absolute; top: 2px; right: 3px;" size="16">mdi-radiobox-marked</v-icon>
                    <div>
                      <div class="text-body-1 font-weight-bold">Medianeira</div>
                      <div class="text-h3 font-weight-bold">
                        {{ listaDados[1].total }}
                      </div>
                    </div>
                    <div class="text-center">
                      <app-chip                        
                        nome="entradas"
                        porcentagem="10"
                        :valor="listaDados[1].entradas"
                      ></app-chip>

                      <app-chip                        
                        nome="renovações"
                        porcentagem="10"
                        :valor="listaDados[1].renovacoes"
                      ></app-chip>

                      <app-chip                        
                        nome="transferências"
                        porcentagem="10"
                        :valor="listaDados[1].transferencias"
                      ></app-chip>

                      <app-chip
                        :saida="true"
                        nome="saídas"
                        porcentagem="10"
                        :valor="listaDados[1].saidas"
                      ></app-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card color="#FBFBFB" elevation="0">
          <v-card-title class="text-h4 font-weight-medium">
            <v-icon class="mr-2 text-h4">mdi-view-list</v-icon> {{ cardSelecionado }}
          </v-card-title>
          <v-card-text class="">
            <app-tabela v-if="alunos.length > 0" :alunos="alunos"></app-tabela>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppChip from "./AppChip.vue";
import AppTabela from "./AppTabela.vue";

import _relatoriosServico from "@/services/relatorios/relatorios-servico.js";

import util from "@/utils/utils.js";

export default {
  components: {
    AppChip,
    AppTabela,
  },
  data() {
    return {
      alunos: [],
      listaDados: [],

      data_inicial: null,
      data_final: null,

      dataInicial: null,
      menuDatePickerI: false,

      dataFinal: null,
      menuDatePickerF: false,

      ativarDatePicker: null,

      cardSelecionado: null,
    };
  },

  mounted() {
    // Criar uma nova data
    let dataAtual = new Date();
    
    // Diminuir 30 dias da data atual
    dataAtual.setDate(dataAtual.getDate() - 30);
    
    this.data_final = util.converterFormatoPtBr(new Date().toISOString());
    this.data_inicial = util.converterFormatoPtBr(dataAtual.toISOString());
    
    this.listarEstatisticas();
  },
  watch: {
    dataInicial() {
      this.data_inicial = util.converterFormatoPtBr(this.dataInicial);
    },
    dataFinal() {
      this.data_final = util.converterFormatoPtBr(this.dataFinal);
    },
  },
  methods: {
    listarEstatisticas() {
      var filtros = {
        data_de: util.formatarData(this.data_inicial),
        data_ate: util.formatarData(this.data_final),
      };
      _relatoriosServico.listarEstatisticas(filtros).then((response) => {
        this.listaDados = response;

        this.popularTabela();

      });
    },
    popularTabela(index = null, selecionado = "Todos") {
      this.cardSelecionado = selecionado
      this.alunos =
        index === null
          ? this.listaDados[0].alunos.concat(this.listaDados[1].alunos)
          : this.listaDados[index].alunos;
    },
  },
};
</script>