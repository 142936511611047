<template>   
    <v-container fluid>
        <AppBar titulo="Avaliações" destino="alunos"/>
        <v-row align="center" justify="center">
            <v-col col="12" md="12" xl="12" xs="12" sm="12">
                <v-card>                    
                    <v-card-text>
                        <v-row>
                            <v-col col="6" md="6" xl="6">
                                <v-text-field
                                    v-model="nome"
                                    label="Aluno"
                                    color="#FF562B"
                                    outlined
                                    disabled>
                                </v-text-field>
                            </v-col>
                            <v-col col="6" md="6" xl="6">
                                <v-text-field
                                    v-model="treinador"
                                    label="Treinador"
                                    color="#FF562B"
                                    outlined
                                    disabled>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn color="#FF562B" dark @click="Buscar()">Buscar</v-btn>
                        <v-btn color="#ccc" dark>Limpar</v-btn> -->
                        <v-btn class="ml-auto" color="#FF562B" dark v-on:click="rota('avaliacao')">Cadastrar</v-btn> 
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
        <v-col col="12" md="12" xl="12" xs="12" sm="12">
            <v-card>        
                <v-data-table
                        :headers="headers"
                        :items="avaliacoes"
                        :items-per-page="10"
                        class="elevation-1"
                        >                
                    <template v-slot:[`item.data`]="{ item }">
                        <span>
                            {{ formatar(item.data) }}
                        </span>
                    </template>
                    <template v-slot:[`item.editar`]="{ item }">
                        <v-icon
                        medium
                        class="mr-2"
                        @click="editarAvaliacao(item.id)"
                        >
                        mdi-pencil-outline
                        </v-icon>
                    </template>
                    <template v-slot:[`item.baixar`]="{ item }">
                    <v-icon
                    medium
                    class="mr-2"
                    @click="baixar(item.id)"
                    >
                    mdi-download-outline 
                    </v-icon>
                </template>                              
                <template v-slot:no-data>
                <v-spacer>
                    <div>
                        <br/>
                    </div>
                </v-spacer>
                <v-alert
                    :value="true"
                    type="info"
                    icon="mdi-alert-circle-outline"
                    color="warning"
                    dark>
                    Nenhuma avaliação cadastrada
                </v-alert>      
                </template>
            </v-data-table>
                    
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
import AppBar from "../../components/AppBar.vue"
import _utils from '../../utils/utils.js'
import _alunoAvaliacao from '../../services/aluno/aluno-avaliacao-servico.js'

export default {    
    components: {    
    AppBar
},
    data () {
        return {
            headers: [
                { text: 'Código', value: 'id' },
                { text: 'Data', align: 'start', value: 'data' },
                { text: 'Treinador', align: 'start', value: 'treinador' },
                { text: 'Editar', value: 'editar', align: 'center', sortable: false },
                { text: 'Baixar', value: 'baixar', align: 'center', sortable: false },
            ],
            id: 0,
            avaliacoes: [],
            nome: '',
            treinador: '',
        }
    },
    mounted() {
        if (this.$route.params.aluno != null)
        {   
            this.aluno = this.$route.params.aluno
            this.nome = this.aluno.nome
            this.treinador = this.aluno.treinador
            this.buscar(this.aluno.id_aluno)
        }
        else {
            this.$router.push({ name: 'alunos' })
        }
    },
    methods: {  
        buscar(id) {
            _alunoAvaliacao.listarAvaliacaoAluno(id).then(response => {
            console.log(response)
            console.log(response[0])
            this.avaliacoes = response
        })
        },
        baixar(id) {
            _alunoAvaliacao.buscarAvaliacaoAluno(id).then(response => {
            var avaliacao = response
            avaliacao.aluno = this.aluno.nome
            avaliacao.treinador = this.aluno.treinador
            avaliacao.genero = this.aluno.sexo
            delete avaliacao.soma_dobras
            delete avaliacao.dc
            delete avaliacao.gordura_corporal
            delete avaliacao.massa_gorda
            delete avaliacao.massa_livre_gordura
            delete avaliacao.rcq
            delete avaliacao.aluno_id
            delete avaliacao.treinador_id
            console.log(avaliacao)
            _alunoAvaliacao.baixarAvaliacaoAluno(avaliacao)
        })
        },
        rota(rota) {        
            //this.$router.replace({name:rota})        
            this.$router.push({name:rota, params: {aluno: this.aluno}})
        },
        editarAvaliacao(id){
            this.$router.push({name:'avaliacao', params: {id: id, aluno: this.aluno}})
        },
        formatar(data){
            return _utils.formatarDataTela(data);
        }
    },
}
</script>