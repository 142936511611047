var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"titulo":"Alunos","destino":"treinadorinicial"}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"col":"12","md":"12","xl":"12","xs":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Filtros ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Nome"},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Ativos","input-value":"true","value":""},model:{value:(_vm.ativos),callback:function ($$v) {_vm.ativos=$$v},expression:"ativos"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Fidelidade","input-value":"true","value":""},model:{value:(_vm.fidelidade),callback:function ($$v) {_vm.fidelidade=$$v},expression:"fidelidade"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"#FF562B","dark":""},on:{"click":function($event){return _vm.listarAlunos()}}},[_vm._v("Buscar")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"#FF562B","dark":""},on:{"click":function($event){return _vm.rota('cadastroalunos')}}},[_vm._v("Cadastrar")])],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"col":"12","md":"12","xl":"12","xs":"12","sm":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.alunos,"items-per-page":10,"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-arrow-left',
                nextIcon: 'mdi-arrow-right',
                  'items-per-page-text':'Itens por página'
            }},scopedSlots:_vm._u([{key:`item.nome`,fn:function({ item }){return [_c('v-avatar',{attrs:{"size":"40px"}},[_c('v-img',{attrs:{"src":`data:image/png;base64,${item.foto}`},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"orange lighten-2"}})],1)]},proxy:true}],null,true)})],1),_c('span',[_vm._v(" "+_vm._s(item.nome))])]}},{key:`item.cadastro`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editarAluno(item)}}},[_vm._v(" mdi-pencil-outline ")])]}},{key:`item.contrato`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editarContrato(item)}}},[_vm._v(" mdi-file-sign ")])]}},{key:`item.agenda`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editarAgenda(item)}}},[_vm._v(" mdi-calendar-clock-outline ")])]}},{key:`item.anamnese`,fn:function({ item }){return [_c('v-icon',{staticStyle:{"align":"center"},attrs:{"medium":""},on:{"click":function($event){return _vm.editarAnamnese(item)}}},[_vm._v(" mdi-account-edit-outline ")])]}},{key:`item.avaliacao`,fn:function({ item }){return [_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.editarAvaliacao(item)}}},[_vm._v(" mdi-clipboard-check-outline ")])]}},{key:"no-data",fn:function(){return [_c('v-spacer',[_c('div',[_c('br')])]),_c('v-alert',{attrs:{"value":true,"type":"info","icon":"mdi-alert-circle-outline","color":"warning","dark":""}},[_vm._v(" Nenhum aluno encontrado. ")])]},proxy:true}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }