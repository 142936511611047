<template>   
    <v-container fluid>
        <AppBar titulo="Anamnese" destino="alunos" />
        <AppSnackbar :texto="texto" :snackbar="snackbar" :vertical="true"/>        
        <!-- <AppAlerta :snack="snack" /> -->
        <v-row align="center" justify="center">
            <v-col col="12" md="12" xl="12" xs="12" sm="12">
                <v-card>
                    <ValidationObserver ref="form" #default="{ handleSubmit }">
                        <v-form @keyup.enter="handleSubmit(salvar)" 
                                    @submit.prevent="handleSubmit(salvar)">                        
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" lg="6" md="4">
                                        <v-text-field
                                            v-model="nome"
                                            label="Aluno"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="treinador"
                                            label="Treinador"
                                            color="#FF562B"
                                            outlined
                                            disabled>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="data"
                                            label="Data"
                                            color="#FF562B"
                                            outlined
                                            v-mask="'##/##/####'">
                                        </v-text-field>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                            <v-text-field
                                                v-model="idade"
                                                label="Idade"
                                                color="#FF562B"
                                                outlined/>                                                                                              
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                        <v-text-field
                                            v-model="peso"
                                            label="Peso"
                                            color="#FF562B"
                                            outlined>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="12" md="12" xl="12">
                                        <v-textarea
                                            v-model="objetivo"
                                            label="Qual seu principal objetivo com exercício personalizado aqui na Let's?"
                                            color="#FF562B"                                            
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                </v-row>     
                                <v-row>
                                    <v-col cols="12" lg="6" md="4">
                                        <v-slider
                                            v-model="imagemCorporal"
                                            stpe="1"
                                            max="7"
                                            label="Satisfação imagem corporal (0 a 7)"
                                            color="#FF562B"
                                            track-color="grey"
                                            thumb-label="always"
                                            outlined>
                                        </v-slider>
                                    </v-col>
                                    <v-col>
                                        <v-slider
                                            v-model="estressAnsiedade"
                                            stpe="1"
                                            max="7"
                                            label="Nível de estresse e ansiedade (0 a 7)"
                                            color="#FF562B"
                                            track-color="grey"
                                            thumb-label="always"
                                            outlined>
                                    </v-slider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="tempoPratica"
                                            label="Qual maior tempo praticando exercício sem interromper"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="atividadeProfissional"
                                            label="Atividade profissional"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-slider
                                            v-model="horasTrabalho"
                                            stpe="1"
                                            max="20"
                                            label="Horas de trabalho"
                                            color="#FF562B"
                                            track-color="grey"
                                            thumb-label="always"
                                            outlined>
                                        </v-slider>                                        
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-slider
                                            v-model="horasSono"
                                            stpe="1"
                                            max="20"
                                            label="Horas de sono"
                                            color="#FF562B"
                                            track-color="grey"
                                            thumb-label="always"
                                            outlined>
                                        </v-slider>                                        
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-combobox
                                            v-model="consumoAcucar"                                            
                                            :items="consumo"
                                            label="Consome pouco, médio, muito açúcar"
                                            color="#FF562B"                                            
                                            outlined>
                                        </v-combobox>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="vicioAlimentar"
                                            label="Algum vício alimentar"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="patologia"
                                            label="Patologia"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="lesao"
                                            label="Lesão"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="disfuncao"
                                            label="Disfunção"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="fratura"
                                            label="Fratura"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="historicoFamiliar"
                                            label="Histórico Familiar"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="medicamento"
                                            label="Toma algum medicamento"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="semExercicio"
                                            label="Quanto tempo está sem exercício"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="significadoExercicio"
                                            label="O que significa exercício pra você"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="objetivoExercicio"
                                            label="Qual seu objetivo com o exercício"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="pontual"
                                            label="Você se considerar uma pessoa pontual"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="automotivada"
                                            label="Se considera uma pessoa automotivada"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="felicidade"
                                            label="O que é felicidade pra você"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="qualidade"
                                            label="Uma qualidade"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="defeito"
                                            label="Um defeito"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="6" md="6" xl="6" sm="12">
                                        <v-textarea
                                            v-model="gostoMusical"
                                            label="Qual seu gosto musical"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                    <v-col col="6" md="6" xl="6">
                                        <v-textarea
                                            v-model="habitoIncluir"
                                            label="Um hábito que você deseja incluir fora exercício:"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col col="4" md="4" xl="4">
                                        <v-combobox
                                            v-model="covid"
                                            :items="opcovid"
                                            label="Testou positivo covid"
                                            color="#FF562B"                                            
                                            outlined>
                                        </v-combobox>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4">
                                        <v-slider
                                            v-model="vacinas"
                                            stpe="1"
                                            max="5"
                                            label="Quantas doses da vacina"
                                            color="#FF562B"
                                            track-color="grey"
                                            thumb-label="always"
                                            outlined>
                                        </v-slider>
                                    </v-col>
                                    <v-col col="4" md="4" xl="4" sm="12">
                                        <v-textarea
                                            v-model="sequela"
                                            label="Alguma sequela"
                                            color="#FF562B"
                                            rows="1"
                                            auto-grow
                                            outlined>
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider />
                                <v-card-actions>
                                    <v-row>
                                        <v-col col="12" md="6" xl="6">
                                            <v-btn color="#FF562B" width="100%" type="submit" dark>Salvar</v-btn>
                                        </v-col>
                                        <v-col col="12" md="6" xl="6">
                                            <v-btn @click="cancelar" color="grey" width="100%" dark>Cancelar</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
//Componentes
import AppBar from "../../components/AppBar.vue"
import AppSnackbar from "../../components/AppSnackbar.vue"
// import AppAlerta from "../../components/AppAlerta.vue";

//Classes
import _utils from '../../utils/utils.js'
import _alunoAnamnese from "../../services/aluno/aluno-anamnese-servico.js"


export default {    
    components: {    
     AppBar,
    // AppAlerta
    AppSnackbar
},
    data () {
        return {
            snack: { 
                snackbar: false,               
                texto: '',
                cor: ''
            },
            id: '',
            nome: '',
            treinador: '',
            data: '',
            idade: 0,
            peso: '',
            objetivo: '',
            imagemCorporal: 0,
            estressAnsiedade: 0,
            tempoPratica: '',
            atividadeProfissional: '',
            horasTrabalho: 0,
            horasSono: 0,
            consumoAcucar: '',
            vicioAlimentar: '',
            patologia: '',
            lesao: '',
            disfuncao: '',
            fratura: '',
            historicoFamiliar: '',
            medicamento: '',
            semExercicio: '',
            significadoExercicio: '',
            objetivoExercicio: '',
            pontual: '',
            automotivada: '',
            felicidade: '',
            qualidade: '',
            defeito: '',
            gostoMusical: '',
            habitoIncluir: '',
            covid: false,
            sequela: '',
            vacinas: 0,
            anamnese: [],
            isDisabled: true,
            consumo: [
                'Pouco',
                'Médio',
                'Muito',
            ],
            opcovid: ['Não', 'Sim'],
            snackbar: false,
            texto: 'Lorem ipsum dolor sit amet',
            vertical: true,
        }
    },

    mounted() {    
        if (this.$route.params.aluno) {              
            //localStorage.aluno = JSON.stringify(this.$route.params.aluno)
            this.aluno = this.$route.params.aluno
            this.nome = this.$route.params.aluno.nome
            this.treinador = this.$route.params.aluno.treinador            
            this.buscar(this.aluno.id)
        }
        else {
            this.aluno = JSON.parse(localStorage.aluno)
            this.nome = this.aluno.nome
            this.treinador = this.aluno.treinador
        }
    },

    methods: {      
        buscar(aluno_id)
        {
            _alunoAnamnese.buscarAnamneseAluno(aluno_id).then((response)=> {
                this.id = response.id
                this.data = _utils.formatarDataTela(response.data)
                this.idade = response.idade
                this.peso = response.peso
                this.objetivo = response.objetivo
                this.imagemCorporal = response.imagem_corporal
                this.estressAnsiedade = response.estress_ansiedade
                this.tempoPratica = response.tempo_pratica
                this.atividadeProfissional = response.atividade_profissional
                this.horasTrabalho = response.horas_trabalho
                this.horasSono = response.horas_sono
                this.consumoAcucar = this.qtdConsumoAcucar(response.consumo_acucar)
                this.vicioAlimentar = response.vicio_alimentar
                this.patologia = response.patologia
                this.lesao = response.lesao
                this.disfuncao = response.disfuncao
                this.fratura = response.fratura
                this.historicoFamiliar = response.historico_familiar
                this.medicamento = response.medicamento
                this.semExercicio = response.sem_exercicio
                this.significadoExercicio = response.significado_exercicio
                this.objetivoExercicio = response.objetivo_exercicio
                this.pontual = response.pontual
                this.automotivada = response.automotivada
                this.felicidade = response.felicidade
                this.qualidade = response.qualidade
                this.defeito = response.defeito
                this.gostoMusical = response.gosto_musical
                this.habitoIncluir = response.habito_incluir
                this.covid = response.covid == true ? 'Sim' : 'Não'
                this.sequela = response.sequela
                this.vacinas = response.vacinas
            })
        },      
        salvar(){
            this.anamnese = {
                aluno_id: this.aluno.id,
                data: _utils.formatarData(this.data),
                idade: this.idade,
                peso: parseFloat(this.peso),
                objetivo: this.objetivo,
                imagem_corporal: this.imagemCorporal,
                estress_ansiedade: this.estressAnsiedade,
                tempo_pratica: this.tempoPratica,
                atividade_profissional: this.atividadeProfissional,
                horas_trabalho: this.horasTrabalho,
                horas_sono: this.horasSono,
                consumo_acucar: this.qtdConsumoAcucar(this.consumoAcucar),
                vicio_alimentar: this.vicioAlimentar,
                patologia: this.patologia,
                lesao: this.lesao,
                disfuncao: this.disfuncao,
                fratura: this.fratura,
                historico_familiar: this.historicoFamiliar,
                medicamento: this.medicamento,
                sem_exercicio: this.semExercicio,
                significado_exercicio: this.significadoExercicio,
                objetivo_exercicio: this.objetivoExercicio,
                pontual: this.pontual,
                automotivada: this.automotivada,
                felicidade: this.felicidade,
                qualidade: this.qualidade,
                defeito: this.defeito,
                gosto_musical: this.gostoMusical,
                habito_incluir: this.habitoIncluir,
                covid: this.covid == 'Sim' ? true : false,
                sequela: this.sequela,
                vacinas: this.vacinas,
            }
                        
            if (this.id == 0) {
                console.log(this.anamnese)
                this.cadastrar()
            } else {
                this.atualizar()
            }
        },
        cadastrar()
        {
            _alunoAnamnese.cadastrarAnamnese(this.anamnese).then((response) => {
                this.id = response.id                
                this.snack.texto = 'Anamnese cadastrada com sucesso!'
                this.snack.cor = 'success';
                this.snack.snackbar = true;                
                //alert("Avaliação cadastrada")
                //setTimeout(() => {  this.$router.push({ name: 'usuarios' }) }, 1000);
            })            
        },
        atualizar()
        {
            _alunoAnamnese.atualizarAnamnese(this.id, this.anamnese).then(() => {
                this.snack.texto = 'Anamnese atualizada com sucesso!'
                this.snack.snackbar = true;   
                this.snackbar = true;             
                //alert("Avaliação atualizada")
                //setTimeout(() => {  this.$router.push({ name: 'usuarios' }) }, 1000);
            })        
        },        
        cancelar()
        {   
            this.isDisabled = true;      
            this.$router.replace({name:'alunos'})
        },                    

        qtdConsumoAcucar(opcao){
            if (opcao == 0)
                return 'Pouco'
            else if (opcao == 1)
                return 'Médio'
            else if (opcao == 2)
                return 'Muito'
            else if (opcao == 'Pouco')
                return 0
            else if (opcao == 'Médio')
                return 1
            else if (opcao == 'Muito')
                return 2
        }
    }
}
</script>