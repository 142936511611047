<!-- ScheduleTable.vue -->
<template>
  <v-row>
    <v-col cols="12">
      <v-breadcrumbs
        class="float-right"
        :items="items"
        divider="/"
      ></v-breadcrumbs>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="schedule"
        hide-default-footer
        disable-pagination
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Dias da Semana</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.hour }}</td>
            <td v-for="(day, index) in days" :key="day">
              <v-checkbox
                v-model="item[day]"
                hide-details
                :disabled="item.disabled"
                @click="salvar(item[day], index + 1, item)"
              ></v-checkbox>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import _treinador from "../../services/treinador/treinador-servico.js";
import Agenda from "../../models/agenda.js";

export default {
  data() {
    const items = [
      {
        text: "Inicial",
        disabled: false,
        href: "/dashboard",
      },
      {
        text: "Treinadores",
        disabled: false,
        href: "/treinadores",
      },
      {
        text: "Agenda de horários do treinador",
        disabled: true,
      },
    ];

    const treinador_id = this.$route.params.id;

    const hours = Array.from({ length: 17 }, (_, i) => i + 6 + ":00");
    const days = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
    // const hours = ['06h', '07h, '08h', '09h']
    // const professorSchedule = [
    //   {
    //     "id": 367,
    //     "dia_semana": 1,
    //     "dia": "Seg",
    //     "horario": "06:00",
    //     "treinador_id": 13,
    //     "numero_alunos": 8,
    //     "alunos": ""
    //   },
    // ];

    const professorSchedule = [];

    const headers = [
      { text: "Horário", value: "hour" },
      ...days.map((day) => ({ text: day, value: day })),
    ];

    // const schedule = hours.map(hour => {
    //   const item = { hour };
    //   days.forEach(day => {
    //     item[day] = false;
    //   });
    //   return item;
    // });

    // Modificando a geração do schedule para incluir um campo desabilitado

    const schedule = [];

    return {
      treinador_id,
      items,
      days,
      hours,
      headers,
      schedule,
      professorSchedule,
    };
  },
  async mounted() {
    // await _treinador
    //   .buscarAgendaTreinador(this.treinador_id)
    //   .then((retorno) => {
    //     console.log(retorno);
    //   });

    this.gerenciarHorarios();
  },
  methods: {
    salvar(elemento, dia, horario) {
      console.log(elemento, dia, horario);
      var agenda = new Agenda();

      agenda = {
        dia_semana: dia,
        horario: horario.hour,
        treinador_id: this.treinador_id,
      };

      if (elemento)
        _treinador.cadastrarAgenda(agenda).then(() => this.gerenciarHorarios());
      else
        _treinador
          .deletarAgenda(horario.id)
          .then(() => this.gerenciarHorarios());
    },

    async gerenciarHorarios() {
      await _treinador.listarAgenda(this.treinador_id).then((retorno) => {
        retorno.forEach((item) => {
          item.horario = `${item.horario.split(":")[0]}:${
            item.horario.split(":")[1]
          }`;
          item.dia = this.days[item.dia_semana - 1];
        });

        this.professorSchedule = retorno;
      });

      this.montarHorario();
    },

    formatHour(hour) {
      const paddedHour = hour.toString().padStart(2, "0");
      return `${paddedHour}:00`;
    },

    montarHorario() {
      this.schedule = this.hours.map((hour) => {
        const item = { hour, disabled: false, id: 0 };
        this.days.forEach((day) => {
          const professorEntry = this.professorSchedule.find(
            (entry) =>
              entry.dia === day &&
              entry.horario === `${this.formatHour(parseInt(hour))}`
          );

          if (professorEntry) {
            item.id = professorEntry.id;
            item[day] = true; // Marcar como verdadeiro se o horário do professor existir

            // if (professorEntry.alunos.length > 0) item.disabled = true; // Desabilitar o campo se houver alunos
          } else {
            item[day] = false;
          }
        });
        // console.log(item)
        return item;
      });
    },
  },
};
</script>
