<template>
  <div>
  <v-dialog v-model="dialog" max-width="500px" @input="onDialogInput">
    <v-card>
      <v-toolbar color="primary" dark
        >Definir taxas para emissão de Notas Fiscais
        <v-spacer></v-spacer>
        <v-btn icon @click="fecharModal()">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <ValidationObserver ref="form" #default="{ handleSubmit }">
        <v-form
          @keyup.enter="handleSubmit(salvar)"
          @submit.prevent="handleSubmit(salvar)"
        >
          <v-card-text class="mt-4">
            <v-row>
              <v-col class="d-flex" cols="12" md="5">
                <v-select
                  :items="academias"
                  v-model="configNota.academia_id"
                  label="Academia"
                  item-text="nome"
                  item-value="id"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <v-col  cols="10" md="5">
                <v-menu
                  v-model="menuDatePickerI"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <ValidationProvider
                      #default="{ errors }"
                      name="Data Referência"
                    >
                      <v-text-field
                        v-model="data_referencia"
                        label="Data Referência"
                        prepend-inner-icon="mdi-calendar"
                        :error-messages="errors[0]"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      />
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="dataInicial"
                    type="month"
                    :active-picker.sync="ativarDatePicker"
                    min="2024-01-01"
                    locale="pt-BR"
                  />
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-btn
                  icon
                  color="primary"
                  height="55"
                  width="100%"
                  @click="buscarConfiguracao()"
                >
                  <v-icon>mdi mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-checkbox
                  v-model="configNota.tomador_nota_reter_iss"
                  label="Tomador Nota Retem ISS"
                  hide-details
                  :disabled="desabilitarSalvar"
                />
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="ISS"
                  v-money="moneyConfig"
                >
                  <v-text-field
                    v-model="configNota.aliquota_iss"
                    label="ISS"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                    :disabled="desabilitarSalvar"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="COFINS"
                  v-money="moneyConfig"
                >
                  <v-text-field
                    v-model="configNota.aliquota_cofins"
                    label="COFINS"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                    :disabled="desabilitarSalvar"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="CSLL"
                  v-money="moneyConfig"
                >
                  <v-text-field
                    v-model="configNota.aliquota_csll"
                    label="CSLL"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                    :disabled="desabilitarSalvar"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="INSS"
                  v-money="moneyConfig"
                >
                  <v-text-field
                    v-model="configNota.aliquota_inss"
                    label="INSS"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                    :disabled="desabilitarSalvar"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="IR"
                  v-money="moneyConfig"
                >
                  <v-text-field
                    v-model="configNota.aliquota_ir"
                    label="IR"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                    :disabled="desabilitarSalvar"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="PIS"
                  v-money="moneyConfig"
                >
                  <v-text-field
                    v-model="configNota.aliquota_pis"
                    label="PIS"
                    :error-messages="errors[0]"
                    outlined
                    hide-details
                    :disabled="desabilitarSalvar"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </ValidationObserver>
      <v-card-actions class="justify-end">
        <v-btn text color="error" @click="fecharModal()"> Cancelar </v-btn>
        <v-btn :disabled="desabilitarSalvar" color="primary" @click="salvar()"
          >Salvar <v-icon class="ml-2">mdi mdi-content-save</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="snackbar.mostrar" :color="snackbar.cor">
      {{ snackbar.texto }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.mostrar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import _academiaServico from '../../services/academia/academia-servico.js'
import _notasFiscais from "../../services/notasfiscais/notafiscal.js";

import ConfiguracaoNotaFiscal from "../../models/configuracao-notafiscal.js";

import util from "../../utils/utils.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    value: Boolean,
  },

  data() {
    return {
      snackbar: {
        mostrar: false,
        texto: "",
        cor: "",
      },

      configNota: new ConfiguracaoNotaFiscal(0, null, false, 0, 0, 0, 0, 0, 0),

      academias: [],

      desabilitarSalvar: true,

      data_referencia: null,

      dataInicial: null,
      menuDatePickerI: false,

      ativarDatePicker: null,

      moneyConfig: {
        // Configuration options for v-money
        decimal: ",",
        // Decimal separator
        thousands: ".",
        // Thousands separator
        prefix: "",
        // Currency symbol
        precision: 2,
        // Number of decimal places
        masked: false,
        // Do not show placeholders for unfilled digits
        allowBlank: false,
        // Do not allow an empty value
      },
    };
  },
  mounted() {
    _academiaServico.listarAcademias().then( academias => {''
      this.academias = academias
    })
  },

  watch: {
    dataInicial() {
      //this.data_referencia = util.converterFormatoPtBr(this.dataInicial);
      const [ano, mes] = this.dataInicial.split("-")
      this.data_referencia = `${mes}/${ano}`
    },
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
    
  methods: {
    notificar(texto, cor = "primary") {
      this.snackbar.cor = cor;
      this.snackbar.texto = texto;
      this.snackbar.mostrar = true;
    },

    fecharModal(){
      this.configNota = new ConfiguracaoNotaFiscal(0, null, false, 0, 0, 0, 0, 0, 0)
      this.desabilitarSalvar = true;
      this.$emit('fechar')
    },
    
    onDialogInput(val) {
      if (!val) {
        this.configNota = new ConfiguracaoNotaFiscal(0, null, false, 0, 0, 0, 0, 0, 0)
        this.desabilitarSalvar = true;
      }
    },

    async buscarConfiguracao() {
      if(this.data_referencia && this.configNota.academia_id){

        try {
          let dataRef = util.formatarData(`01/${this.data_referencia}`)
          
          this.desabilitarSalvar = false;        
          
          _notasFiscais.buscarConfiguracoes(this.configNota.academia_id, dataRef).then(config => {            
            if(config){
              this.configNota.id =  config.id

              this.configNota.aliquota_iss = parseFloat(config.aliquota_iss).toFixed(2);
              this.configNota.aliquota_cofins = parseFloat(config.aliquota_cofins).toFixed(2);
              this.configNota.aliquota_csll = parseFloat(config.aliquota_csll).toFixed(2);
              this.configNota.aliquota_inss = parseFloat(config.aliquota_inss).toFixed(2);
              this.configNota.aliquota_ir = parseFloat(config.aliquota_ir).toFixed(2);
              this.configNota.aliquota_pis = parseFloat(config.aliquota_pis).toFixed(2);
            }
          });
          
        } catch (erro) {
          this.notificar(
            "Erro ao buscar as configurações de notas fiscais!",
            "error"
          );
        }
      }
    },

    async salvar() {
      try {
        this.configNota.aliquota_iss = parseFloat(this.configNota.aliquota_iss.replace(/[^\d-,]/g, "").replace(",", ".")).toFixed(2);
        this.configNota.aliquota_cofins = parseFloat(this.configNota.aliquota_cofins.replace(/[^\d-,]/g, "").replace(",", ".")).toFixed(2);
        this.configNota.aliquota_csll = parseFloat(this.configNota.aliquota_csll.replace(/[^\d-,]/g, "").replace(",", ".")).toFixed(2);
        this.configNota.aliquota_inss = parseFloat(this.configNota.aliquota_inss.replace(/[^\d-,]/g, "").replace(",", ".")).toFixed(2);
        this.configNota.aliquota_ir = parseFloat(this.configNota.aliquota_ir.replace(/[^\d-,]/g, "").replace(",", ".")).toFixed(2);
        this.configNota.aliquota_pis = parseFloat(this.configNota.aliquota_pis.replace(/[^\d-,]/g, "").replace(",", ".")).toFixed(2);

        this.configNota.data_referencia = util.formatarData(`01/${this.data_referencia}`)

        _notasFiscais.salvalConfiguracoes(this.configNota).then(() => {
          this.cobrancaSelecionada = null;
          this.notificar(
            "Configurações de notas fiscais salvas com sucesso!",
            "success"
          );
        })
        .catch(() => {
          this.cobrancaSelecionada = null;
          this.notificar(
            "Erro ao salvar as configurações de notas fiscais!",
            "error"
          );
        });

        this.fecharModal()
      } catch (erro) {
        console.error("Erro ao salvar configuração notas:", erro);
      }
    },
  },
};
</script>