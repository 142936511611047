/**
 * @typedef {Object} Assinatura
 * @property {number} id
 * @property {number} idenvelope
 * @property {number} idaluno
 * @property {String} nomeenvelope
 * @property {Date} criacao
 * @property {number} status
 * @property {String} motivocancelamento
 */
export default class Assinatura {
    constructor(
      id,
      idenvelope,
      idaluno,
      nomeenvelope,
      criacao,
      status,
      motivocancelamento
    ) {
      this.id = id
      this.idenvelope = idenvelope
      this.idaluno= idaluno
      this.nomeenvelope = nomeenvelope
      this.criacao = criacao
      this.status = status
      this.motivocancelamento = motivocancelamento
    }
  }
