<template>
    <div>
        <div style="display:flex; align-items: center; width: 100%;">
            <v-icon style="margin-right: 10px">mdi-podium</v-icon>
            <h2>Ranking</h2>
        </div>        
        <div v-for="(treinador, index) in ranking" :key="treinador">
            <v-card
    class="mx-auto"
    max-width="500"
    outlined
  >
    <v-list-item three-line>

        <v-list-item-avatar        
        size="80"
        color="orange"
      >
      <v-icon v-if="index == 0" color="yellow" size="32">mdi-medal-outline</v-icon>
        <v-icon v-if="index == 1" color="grey" size="32">mdi-medal-outline</v-icon>
        <v-icon v-if="index == 2" color="brown" size="32">mdi-medal-outline</v-icon>
        <h1 v-if="index > 2">{{ index + 1 }}°</h1>
    </v-list-item-avatar>      
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-1">
          {{ treinador.nome }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-rating
            background-color="grey"
            color="warning"
            hover
            :length="5"
            :size="24"
            v-model="rating"
            half-increments
            active-color="primary"
            readonly
          />
        </v-list-item-subtitle>
      </v-list-item-content>    
      <v-list-item-avatar        
        size="50"
        color="orange"
      >      
        <h2 style="color: white">{{ rating }}</h2>
    </v-list-item-avatar>      
      
    </v-list-item>

    <!-- <v-card-actions>
      <v-btn
        outlined
        rounded
        text
      >
        Button
      </v-btn>
    </v-card-actions> -->
  </v-card>
        <!-- <v-icon v-if="index == 0" color="yellow">mdi-medal-outline</v-icon>
        <v-icon v-if="index == 1" color="grey">mdi-medal-outline</v-icon>
        <v-icon v-if="index == 2" color="brown">mdi-medal-outline</v-icon>
        <span v-if="index > 2">{{ index + 1 }}° - </span>
        <span>{{ treinador.nome }}</span> -->
        </div>        
    </div>
</template>

<script>
import _treinador from '../services/treinador/treinador-servico.js'
export default {
    data() {
        return {
            ranking: [],
            rating: 3.5
        }
    },

    mounted() {
        this.buscarRanking()
    },
    
    methods: {
        async buscarRanking() {
            this.ranking = await _treinador.buscarRanking()
            console.log(this.ranking)
        },
    }
}
</script>