<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs
          class="float-right"
          :items="items"
          divider="/"
        ></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" sm="12" md="6" lg="3">
        <v-alert
          color="orange"
          dark
          icon="mdi-calendar"
          border="left"
          prominent
          outlined
        >
          <h2 class="text-capitalize mb-3">
            {{ util.retornaStringMes(Dre.mes) }}
          </h2>
          <br />
          <h3>{{ Dre.ano }}</h3>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="3">
        <v-alert
          color="green"
          dark
          icon="mdi-currency-brl"
          border="left"
          prominent
          outlined
        >
          <h2>SALDO EM CONTA</h2>
          <br />
          <span class="text-h4 font-weight-bold">{{ Dre.saldo_inicial }}</span>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="3">
        <v-alert
          color="blue"
          dark
          icon="mdi-cash-plus"
          border="left"
          prominent
          outlined
        >
          <h2>RECEITAS</h2>
          <br />
          <span class="text-h4 font-weight-bold">{{
            calcularTotal([dreLancamento.ENTRADAS])
          }}</span>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="3">
        <v-alert
          color="red"
          dark
          icon="mdi-cash-minus"
          border="left"
          prominent
          outlined
        >
          <h2>DESPESAS</h2>
          <br />
          <span class="text-h4 font-weight-bold">{{
            calcularTotal([
              dreLancamento.DESPESAS_FIXAS,
              dreLancamento.DESPESAS_VARIAVEIS,
              dreLancamento.SALARIOS_ADM,
              dreLancamento.SALARIOS_TREINADORES,
            ])
          }}</span>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span style="color: green">ENTRADAS FINANCEIRAS</span>
              <template v-slot:actions>
                <span style="color: green" class="text-h6">
                  {{ calcularTotal([dreLancamento.ENTRADAS]) }}</span
                >
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <app-dre-lancamento
                :valores="listaDreLancamento"
                :tipo="dreLancamento.ENTRADAS"
                :dre_id="dre_id"
              ></app-dre-lancamento>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="color: red">DESPESAS FIXAS</span>
              <template v-slot:actions>
                <span style="color: red" class="text-h6">
                  {{ calcularTotal([dreLancamento.DESPESAS_FIXAS]) }}</span
                >
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <app-dre-lancamento
                :valores="listaDreLancamento"
                :tipo="dreLancamento.DESPESAS_FIXAS"
                :dre_id="dre_id"
              ></app-dre-lancamento>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="color: red">SALÁRIOS | TREINADORES</span>
              <template v-slot:actions>
                <span style="color: red" class="text-h6">
                  {{
                    calcularTotal([dreLancamento.SALARIOS_TREINADORES])
                  }}</span
                >
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <app-dre-lancamento
                :valores="listaDreLancamento"
                :tipo="dreLancamento.SALARIOS_TREINADORES"
                :dre_id="dre_id"
              ></app-dre-lancamento>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="color: red">SALÁRIOS | ADMINISTRATIVO</span>
              <template v-slot:actions>
                <span style="color: red" class="text-h6">
                  {{ calcularTotal([dreLancamento.SALARIOS_ADM]) }}</span
                >
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <app-dre-lancamento
                :valores="listaDreLancamento"
                :tipo="dreLancamento.SALARIOS_ADM"
                :dre_id="dre_id"
              ></app-dre-lancamento>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <span style="color: red">DESPESAS VARIÁVEIS</span>
              <template v-slot:actions>
                <span style="color: red" class="text-h6">
                  {{ calcularTotal([dreLancamento.DESPESAS_VARIAVEIS]) }}</span
                >
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <app-dre-lancamento
                :valores="listaDreLancamento"
                :tipo="dreLancamento.DESPESAS_VARIAVEIS"
                :dre_id="dre_id"
              ></app-dre-lancamento>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import _util from "../../utils/utils.js";

import Dre from "../../models/dre.js";

import AppDreLancamento from "../../components/finaceiro/AppDreLancamento.vue";
import _dreLancamento from "../../enums/dre-lancamento.js";

import _dre from "../../services/financeiro/dre.js";
import _dreLancamentoService from "../../services/financeiro/dre-lancamento.js";

export default {
  components: {
    AppDreLancamento,
  },
  name: "HelloWorld",

  data() {
    return {
      dre_id: parseInt(this.$route.params.dre_id),

      util: _util,
      Dre: new Dre(),

      mes: 1,
      dreLancamento: _dreLancamento,

      listaDreLancamento: [],
      items: [
        {
          text: "Inicial",
          disabled: false,
          href: "/dashboard",
        },
        {
          text: "Lista Dre",
          disabled: false,
          href: "/lista-financeira",
        },
        {
          text: "Gestão Financeira",
          disabled: true,
        },
      ],
    };
  },

  mounted() {
    this.$root.$refs.AppFinanceiro = this;
    this.buscar();
  },
  methods: {
    async buscar() {
      await _dre.buscar(this.dre_id).then((resultado) => {
        this.Dre = resultado[0];

        this.Dre.saldo_inicial = this.util.formatarDinheiro(
          this.Dre.saldo_inicial
        );

        _dreLancamentoService.listar(this.dre_id).then((retorno) => {
          this.listaDreLancamento = retorno       
         });
      });
    },

    calcularTotal(tipo) {
      return this.util.formatarDinheiro(
        this.listaDreLancamento
          .filter((x) => tipo.includes(x.tipo))
          .reduce((acumulador, objeto) => acumulador + objeto.valor, 0)
      );
    },
  },
};
</script>  