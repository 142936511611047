<template>
  <v-container fluid>
    <AppConfirma :dialogs="dialogs" @resposta="getResposta" />
    <AppBar titulo="Contratos" destino="alunos" />
    <AppAlerta :snack="snack" />
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <ValidationObserver ref="form" #default="{ handleSubmit }">
            <v-form
              @keyup.enter="handleSubmit(salvar)"
              @submit.prevent="handleSubmit(salvar)"
            >
              <v-card-text>
                <v-row>
                  <v-col col="6" md="6" xl="6">
                    <v-text-field
                      v-model="nome"
                      label="Aluno"
                      color="#FF562B"
                      outlined
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col="6" md="6" xl="6">
                    <v-text-field
                      v-model="treinador"
                      label="Treinador"
                      color="#FF562B"
                      outlined
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="4" md="4" xl="4">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="data incial"
                    >
                      <v-text-field
                        v-model="data_inicial"
                        label="Data Inicial"
                        color="#FF562B"
                        :error-messages="errors[0]"
                        outlined
                        v-mask="'##/##/####'"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col col="4" md="4" xl="4">
                    <v-text-field
                      v-model="data_final"
                      label="Data Final"
                      color="#FF562B"
                      outlined
                      v-mask="'##/##/####'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col="4" md="4" xl="4">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="valor"
                    >
                      <v-text-field
                        v-model="valor"
                        label="Valor"
                        color="#FF562B"
                        :error-messages="errors[0]"
                        outlined                        
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="forma de pagamento"
                    >
                      <v-select
                        v-model="forma_pagamento"
                        :items="formaPagamento"
                        label="Forma de Pagamento"
                        color="#FF562B"
                        :error-messages="errors[0]"
                        outlined
                      >
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col col="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="tipo de contrato"
                    >
                      <v-select
                        v-model="tipo"
                        :items="tipoContrato"
                        label="Tipo de Contrato"
                        color="#FF562B"
                        :error-messages="errors[0]"
                        outlined
                      >
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-row>
                  <v-col col="4" md="4" xl="4">
                    <v-btn
                      :disabled="SalvarDesativado"
                      color="#FF562B"
                      width="100%"
                      type="submit"
                      dark
                      >Salvar</v-btn
                    >
                  </v-col>
                  <v-col col="4" md="4" xl="4">
                    <v-btn
                      color="red"
                      width="100%"
                      dark
                      @click="excluir()"
                      :disabled="isDisabled"
                      >Excluir</v-btn
                    >
                  </v-col>
                  <v-col col="4" md="4" xl="4">
                    <v-btn @click="cancelar" color="grey" width="100%" dark
                      >Cancelar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="listaContratos"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.forma_pagamento`]="{ item }">
              <span>
                {{ getFormaPagamento(item.forma_pagamento) }}
              </span>
            </template>
            <template v-slot:[`item.tipo`]="{ item }">
              <span>
                {{ getTipoContrato(item.tipo) }}
              </span>
            </template>
            <template v-slot:[`item.pagamentos`]="{ item }">
              <v-icon medium class="mr-2" @click="verPagamentos(item)">
                mdi-currency-usd
              </v-icon>
            </template>
            <template v-slot:[`item.editar`]="{ item }">
              <v-icon medium class="mr-2" @click="editarContrato(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <template v-slot:[`item.gerar`]="{ item }">
              <v-icon
                v-if="!item.possuiContrato"
                medium
                class="mr-2"
                @click="gerarContrato(item.id)"
              >
                mdi-file-sign
              </v-icon>
              <v-icon @click="abrirModalAssinaturas(item.id)" v-else>
                mdi-eye
              </v-icon>
            </template>
            <!-- <template v-slot:[`item.excluir`]="{ item }">
                            <v-icon
                            medium
                            style="align: center"
                            @click="excluirContrato(item.id)"
                            >
                            mdi-trash-can-outline
                            </v-icon>
                        </template>             -->
          </v-data-table>
        </v-card>
      </v-col>
      <app-modal-assinatura
        v-if="modalAssinaturas"
        v-model="modalAssinaturas"
        :contrato-id="idSelecionado"
      ></app-modal-assinatura>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AppBar from "../../components/AppBar.vue";
import AppAlerta from "../../components/AppAlerta.vue";
import AppConfirma from "../../components/AppConfirma.vue";
import _aluno from "../../services/aluno/aluno-servico.js";
import _contrato from "../../services/contrato/contrato-servico.js";
//import _pagamento from '../../services/pagamento/pagamento-servico.js'
import _utils from "../../utils/utils.js";
import _formaPagamento from "../../models/forma-pagamento.js";
import _tipoContrato from "../../models/tipo-contrato.js";
import Assinatura from "../../models/assinatura.js";
import _ferramentas from "../../services/ferramentas/contrato/contrato.js";

import AppModalAssinatura from "./AppModalAssinaturas.vue";

export default {
  components: {
    AppAlerta,
    AppBar,
    AppConfirma,
    ValidationObserver,
    ValidationProvider,
    AppModalAssinatura,
  },

  data() {
    return {
      dialogs: {
        dialog: false,
        titulo: "",
      },
      snack: {
        snackbar: false,
        texto: "",
        cor: "",
      },
      id: 0,
      aluno: [],
      nome: "",
      treinador: "",
      data_inicial: "",
      data_final: "",
      valor: "",
      forma_pagamento: "",
      tipo: "",
      contratos: [],
      SalvarDesativado: true,
      isDisabled: true,
      headers: [
        { text: "Código", value: "id" },
        { text: "Data Inicial", align: "start", value: "data_inicial" },
        { text: "Data Final", value: "data_final" },
        { text: "Forma de Pagamento", value: "forma_pagamento" },
        { text: "Tipo", value: "tipo" },
        { text: "Valor", value: "valor" },
        {
          text: "Pagamentos",
          value: "pagamentos",
          align: "center",
          sortable: false,
        },
        { text: "Editar", value: "editar", align: "center", sortable: false },
        {
          text: "Gerenciar Contrato",
          value: "gerar",
          align: "center",
          sortable: false,
        },
        //   { text: 'Excluir', value: 'excluir', align: 'center', sortable: false },
      ],
      formaPagamento: _formaPagamento,
      tipoContrato: _tipoContrato,

      Assinatura: new Assinatura(),
      modalAssinaturas: false,
      idSelecionado: null,

      listaContratos: [],
    };
  },

  mounted() {
    console.log('dafasdf')    
    if (this.$route.params.aluno != null) {      
      this.aluno = this.$route.params.aluno;
      // console.log(this.aluno)
      this.nome = this.aluno.nome;
      this.treinador = this.aluno.treinador;
      this.listarContratos(this.aluno.id_aluno);
      if (this.id == 0) {
        this.SalvarDesativado = false;
      }
    } else {
      this.$router.push({ name: "alunos" });
    }
  },

  methods: {
    buscar(id) {
      _aluno.buscarAluno(id).then((response) => {
        this.aluno = response;
        this.nome = this.aluno.nome;
        this.treinador = this.aluno.treinador_id;
      });
    },
    getFormaPagamento(forma) {
      return _formaPagamento[forma].text;
    },
    getTipoContrato(tipo) {
      console.log(tipo)
      return _tipoContrato[tipo].text;
    },
    listarContratos(id) {
      _contrato.buscarContratoAluno(id).then((response) => {
        //console.log(response)
        this.contratos = response;
        this.listaContratos = []

        this.contratos.forEach(async (x) => {
          await this.buscarContrato(x.id).then((y) => {
            x.possuiContrato = y.assinatura
            this.listaContratos.push(x)
          });
        });
      });
    },

    salvar() {
      this.contrato = {
        aluno_id: this.aluno.id_aluno,
        data_inicial: _utils.formatarData(this.data_inicial),
        data_final:
          this.data_final == "" ? null : _utils.formatarData(this.data_final),
        valor: this.valor,
        forma_pagamento: this.forma_pagamento,
        tipo: this.tipo,
        treinador_id: this.aluno.id_treinador,
      };
      if (this.id == 0) {
        //console.log(this.contrato)
        this.cadastrarContrato();
      } else {
        console.log(this.contrato)
        this.atualizar();
      }
    },
    cadastrarContrato() {
      _contrato.cadastrarContrato(this.contrato).then(() => {
        this.gerarPagamentos(this.contrato.forma_pagamento);
        this.snack.texto = "Contrato cadastrado com sucesso!";
        this.snack.snackbar = true;
        setTimeout(() => {
          this.listarContratos(this.aluno.id);
        }, 500);
        this.limparCampos();
      });
    },
    gerarPagamentos(){
          for (let i = 0; i < this.contratos.forma_pagamento; i++)
          {
            //console.log(i);
            //_pagamento.cadastrarPagamento()
          }
        },
    atualizar() {
      _contrato.atualizarContrato(this.id, this.contrato).then(() => {
        this.snack.texto = "Contrato atualizado com sucesso!";
        this.snack.cor = "success";
        this.snack.snackbar = true;
        setTimeout(() => {
          this.listarContratos(this.aluno.id);
        }, 500);
        this.id = 0;
        this.limparCampos();
      });
    },
    editarContrato(contrato) {
      this.id = contrato.id;
      this.data_inicial = contrato.data_inicial;
      this.data_final = contrato.data_final;
      this.valor = contrato.valor;
      this.forma_pagamento = contrato.forma_pagamento;
      this.tipo = contrato.tipo;
      // verificar se não tem pagamentos para habilitar o excluir
      this.isDisabled = false;
    },
    excluir() {
      this.dialogs.dialog = true;
      this.dialogs.titulo = "Excluir Contrato";
      this.dialogs.mensagem = "Deseja excluir o contrato?";
    },
    getResposta(value) {
      this.resposta = value;
      if (this.resposta == true) {
        _contrato.excluirContrato(this.id).then(() => {
          this.snack.texto = "Contrato excluído com sucesso!";
          this.snack.snackbar = true;
          setTimeout(() => {
            this.listarContratos(this.aluno.id);
          }, 500);
        });
      }
    },
    verPagamentos(contrato) {
      this.$router.push({ name: "pagamentos", params: { aluno: this.aluno, id: contrato.id } });
    },
    gerarContrato(id) {
      _contrato.buscarDadosContrato(id).then((result) => {      
        _ferramentas.gerar(result).then(() => {          
          this.listarContratos(this.aluno.id)          
        })
    });
    },
    buscarContrato(id) {
      return _ferramentas.buscarContratoAluno(id).then((result) => {
        return result        
      });
    },
    abrirModalAssinaturas(id) {
      this.idSelecionado = id;
      this.modalAssinaturas = true;
    },
    limparCampos() {
      this.id = 0;
      this.data_inicial = "";
      this.data_final = "";
      this.valor = "";
      this.forma_pagamento = "";
      this.tipo = "";
      this.$refs.form.reset();
    },
    cancelar() {
      this.rota("alunos");
    },
    rota(rota) {
      this.$router.replace({ name: rota });
    },
  },
};
</script>