var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"titulo":"","destino":"dashboard"}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"col":"12","md":"12","xl":"12","xs":"12","sm":"12"}},[_c('v-toolbar',{attrs:{"color":"orange","dense":""}},[_c('v-toolbar-title',[_vm._v("Usuários")])],1),_c('v-divider'),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" Filtros ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Nome","outlined":""},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.listarUsuarios()}}},[_vm._v("Buscar "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-magnify")])],1),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"#FF562B","dark":""},on:{"click":function($event){return _vm.rota('cadastro')}}},[_vm._v("Cadastrar "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-plus")])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"col":"12","md":"12","xl":"12","xs":"12","sm":"12"}},[_c('h3',{staticClass:"mb-2"},[_vm._v("Lista de usuários")]),_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.usuarios,"items-per-page":10,"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                  'items-per-page-text':'Itens por página'
            }},scopedSlots:_vm._u([{key:`item.cadastro`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editarUsuario(item)}}},[_vm._v(" mdi-pencil-outline ")])]}},{key:`item.relacionamento`,fn:function({ item }){return [(item.relacionamento === null)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","title":"Treinador"},on:{"click":function($event){return _vm.cadastrarTreinador(item)}}},[_vm._v(" mdi-human-male-board ")]):_vm._e(),(item.relacionamento === 'Treinador')?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","title":"Treinador"},on:{"click":function($event){return _vm.editarTreinador(item)}}},[_vm._v(" mdi-human-male-board ")]):_vm._e(),(item.relacionamento === null)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","title":"Aluno"},on:{"click":function($event){return _vm.cadastrarAluno(item)}}},[_vm._v(" mdi-walk ")]):_vm._e(),(item.relacionamento === 'Aluno')?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","title":"Aluno"},on:{"click":function($event){return _vm.editarAluno(item.relacionamento_id)}}},[_vm._v(" mdi-walk ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('center',[_c('div',[_c('v-progress-linear',{attrs:{"color":"orange lighten-2","height":"20","indeterminate":"","striped":""}},[_c('span',{staticStyle:{"color":"grey","font-size":"12px"}},[_vm._v("Carregando...")])])],1)])]},proxy:true}],null,true)})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }